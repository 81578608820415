import React, { useContext, useEffect } from "react";
import { setUserContext } from "../appInsights";
import { AuthContext } from "./AuthContext"; // Assuming AuthContext provides user info

function UserContextTrackingWrapper({ children }) {
  const { user } = useContext(AuthContext); // Extract user info from context

  useEffect(() => {
    if (user?.email) {
      setUserContext(user.email); // Set user's email in Application Insights
    } else {
      setUserContext(null); // Clear context when user logs out
    }
  }, [user]);

  return <>{children}</>;
}

export default UserContextTrackingWrapper;
