import Fuse from "fuse.js";

export function getSuggested(template, columnsList, standardName) {
  const fragments = buildTemplate(template);
  let didUseName = false;
  const keyedColumnNames = columnsList?.map((column) => {
    //replace any non char with spaces
    const regex = /[^A-Za-z0-9]/g;
    const nameSplit = column?.name.replace(regex, " ");

    //splits the string leaving abbreviations as separate strings
    const nameArray = nameSplit.split(/([A-Z][a-z]+)/).filter(function (e) {
      return e;
    });

    const removeSecondSpace = nameArray.map((cl) => {
      return cl.split(" ").join("");
    });

    const cleanNameArray = removeSecondSpace.filter(function (str) {
      return /\S/.test(str);
    });

    return {
      keyed: cleanNameArray,
      ...column,
    };
  });

  let values = [];

  fragments.forEach((fragment, i) => {
    //Check if Fragment Type
    if (fragment.def === "fragment") {
      //Add Source Drop Zone Info to Search Loop
      if (fragment?.source?.typeInformation[1].fragmentValue) {
        values.push({
          fragmentId: fragment?.source?.id,
          name: fragment?.source?.typeInformation[1].fragmentValue,
        });
      }
      //If Target Column Type add info to Search Loop
      if (fragment?.target?.typeInformation[1].typeValue === 1) {
        values.push({
          fragmentId: fragment?.target?.id,
          name: fragment?.target?.typeInformation[1].fragmentValue,
        });
      }
    }
  });

  const uniqueValues = [...new Set(values.map((item) => item.name))];

  const uniqueValuesKeyed = uniqueValues?.map((uv) => {
    //replace any non char with spaces
    const regex = /[^A-Za-z0-9]/g;
    const nameSplit = uv?.replace(regex, " ");

    //splits into array with spaces
    const nameArray = nameSplit.split(/([A-Z][a-z]+)/).filter(function (e) {
      return e;
    });

    const cleanNameArray = nameArray.filter(function (str) {
      return /\S/.test(str);
    });

    return {
      keyed: cleanNameArray,
      uv,
    };
  });

  if (uniqueValuesKeyed?.length && keyedColumnNames?.length) {
    const options = {
      includeScore: true,
      minMatchCharLength: 3,
      // Search in 'name', 'description'
      keys: ["keyed"],
      useExtendedSearch: true,
    };

    const fuse = new Fuse(keyedColumnNames, options);

    let suggestions = [];

    uniqueValuesKeyed.forEach((uvk) => {
      uvk.keyed.forEach((keyedValue) => {
        const result = fuse.search(`=${keyedValue}`);
        if (result?.length) {
          result.forEach((r) => {
            suggestions.push(r?.item);
          });
        }
      });
    });

    if (!suggestions?.length) {
      const options = {
        includeScore: true,
        minMatchCharLength: 3,
        useExtendedSearch: true,
        // Search in 'name', 'description'
        keys: ["keyed"],
      };

      const fuse = new Fuse(keyedColumnNames, options);
      const cleanNamePeriod = standardName.replace(/\.$/, "");
      const cleanName = cleanNamePeriod.replace(/\s/g, " | '");
      const result = fuse.search(`'${cleanName}`);
      if (result?.length) {
        didUseName = true;
        result.forEach((r) => {
          suggestions.push(r?.item);
        });
      }
    }

    const uniqueSuggestions = suggestions.filter(
      (v, i, a) => a.indexOf(v) === i
    );

    const weightedUniqueSuggestions = uniqueSuggestions
      .map((us) => {
        return {
          ...us,
          weight: suggestions.filter((s) => s?.id === us?.id)?.length,
        };
      })
      .sort(function (a, b) {
        return b.weight - a.weight;
      });

    return {
      fragments: values,
      suggestions: weightedUniqueSuggestions,
      didUseName,
    };
  } else {
    return [];
  }
}

function buildTemplate(template) {
  let newCards = [];

  const sorted =
    template?.fragments?.sort(function (a, b) {
      return a.executionOrder - b.executionOrder;
    }) ?? [];

  sorted.forEach((frag, i) => {
    const fragType = frag.typeInformation[0].typeValue;
    if (fragType === 1) {
      // Condition

      const conditionNames = ["If", "Else If", "Else", "End If", "Then"];
      const fragment = {
        ...frag,
        name: conditionNames[frag.typeInformation[1].typeValue - 1],
        def: "conditionOperators",
      };

      newCards.push(fragment);
    } else if (fragType === 2) {
      // Condition Operator
      const conditionOperatorNames = ["And", "Or", "(", ")"];
      const fragment = {
        ...frag,
        name: conditionOperatorNames[frag.typeInformation[1].typeValue - 1],
        def: "conditionOperators",
      };
      newCards.push(fragment);
    } else if (fragType === 4) {
      // Source
      //Map Source Dropdown to value for react-select
      const source = frag;
      const operation = sorted[i + 1];

      const isByAmountOrisByPercentage =
        operation.typeInformation[2]?.typeValue === 4 ||
        operation.typeInformation[2]?.typeValue === 5 ||
        operation.typeInformation[2]?.typeValue === 7 ||
        operation.typeInformation[2]?.typeValue === 8;

      const target =
        (operation.typeInformation[1].typeValue === 6 &&
          !isByAmountOrisByPercentage) ||
        (operation.typeInformation[1].typeValue === 14 &&
          !isByAmountOrisByPercentage) ||
        (operation.typeInformation[1].typeValue === 15 &&
          !isByAmountOrisByPercentage)
          ? null
          : sorted[i + 2];

      const fragment = {
        name: "Fragment",
        def: "fragment",
        source: source,
        operation: operation,
        target: target,
      };
      newCards.push(fragment);
    }
  });

  return newCards;
}
