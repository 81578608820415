import React, { useEffect, useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { MdWarning } from "react-icons/md";
import { CheckBoxSuccess, CheckBoxSuccessMsg } from "./CreateFormStyles";
import Modal from "../../../Modal";
import Button from "../../../Button";
import styled, { keyframes } from "styled-components/macro";
import useWorkflowCompleteEvent from "../../../../Hooks/useWorkflowCompleteEvent";
import useWorkflowUpdateEvent from "../../../../Hooks/useWorkflowUpdateEvent";
import ErrorMessages from "../../../Notifications/ErrorMessages";
import { useHistory } from "react-router-dom";
import { workflows } from "../../../../common/paths";

const ellipsis = keyframes`
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
`;

const LoadingLine = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  &:after {
    content: "...";
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsis} 2s infinite 0.3s;
    animation-fill-mode: forwards;
    width: 1.25em;
    text-align: left;
  }
`;

const ShowSuccessModal = ({ workflowData, setShowSuccess, dispatch }) => {
  const [workflowProcess, setWorkflowProcess] = useState();

  const { workflowCreateCompleteEvent, setWorkflowCreateCompleteEvent } =
    useWorkflowCompleteEvent();

  const { workflowUpdateCompleteEvent, setWorkflowUpdateCompleteEvent } =
    useWorkflowUpdateEvent();

  let history = useHistory();

  //updated event
  useEffect(() => {
    if (workflowUpdateCompleteEvent) {
      if (workflowUpdateCompleteEvent?.payload?.ErrorMessage) {
        setWorkflowProcess({
          pass: false,
          message: workflowUpdateCompleteEvent?.payload?.ErrorMessage,
        });
      } else {
        setWorkflowProcess({ pass: true });
      }
    }
  }, [workflowUpdateCompleteEvent]);

  //new created event
  useEffect(() => {
    if (workflowCreateCompleteEvent) {
      if (workflowCreateCompleteEvent?.payload?.ErrorMessage) {
        setWorkflowProcess({
          pass: false,
          message: workflowCreateCompleteEvent?.payload?.ErrorMessage,
        });
      } else {
        setWorkflowProcess({ pass: true });
      }
    }
  }, [workflowCreateCompleteEvent]);

  const resetAll = () => {
    setShowSuccess(false);
    setWorkflowProcess();
    setWorkflowCreateCompleteEvent(null);
    setWorkflowUpdateCompleteEvent(null);
  };

  return (
    <Modal
      disableHide={!workflowProcess}
      hide={() => (!workflowProcess ? null : resetAll())}
    >
      <CheckBoxSuccess workflowProcess={workflowProcess}>
        {workflowProcess && !workflowProcess?.pass ? (
          <MdWarning />
        ) : (
          <FiCheckCircle />
        )}
      </CheckBoxSuccess>

      {workflowProcess ? (
        !workflowProcess?.pass ? (
          <>
            <ErrorMessages errors={[{ message: workflowProcess?.message }]} />
          </>
        ) : (
          <>
            <CheckBoxSuccessMsg>Success!</CheckBoxSuccessMsg>
            <p style={{ textAlign: "center", marginBottom: "2rem" }}>
              Your Workflow has been saved
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="button"
                list="true"
                onClick={() => history.push(workflows())}
              >
                Go to Workflows
              </Button>
              <Button
                type="button"
                onClick={() => {
                  resetAll();
                  dispatch({ type: "RESET_FORM" });
                }}
              >
                Create another Workflow
              </Button>
            </div>
          </>
        )
      ) : (
        <>
          <LoadingLine>
            Building ETL dependencies, this may take a few minutes
          </LoadingLine>
        </>
      )}
    </Modal>
  );
};

export default ShowSuccessModal;
