import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseDataSourceRowsByRowKeyAndRule = () => {
  const { dataSourceRowsByRowKeyAndRule, setDataSourceRowsByRowKeyAndRule } =
    useContext(SocketContext);

  return { dataSourceRowsByRowKeyAndRule, setDataSourceRowsByRowKeyAndRule };
};

export default UseDataSourceRowsByRowKeyAndRule;
