import React from "react";
import styled from "styled-components/macro";
import { StyledSelect, FormControl } from "../../Form/FormControls";
import {
  BusinessRuleOperations,
  BusinessRuleOperationModifiers,
} from "./buildingBlocks";
const NodeBody = styled.div`
  padding: 0.5em;
  flex: 1;
`;

const ModifierCheckBox = ({
  node,
  dispatch,
  index,
  selected,
  children,
  modifierType,
  name,
}) => (
  <label>
    {children}
    <input
      type="checkbox"
      checked={selected}
      onChange={(e) =>
        dispatch({
          type: "UPDATE_MODIFIED_VARIABLE",
          payload: {
            node,
            value: e.target.checked,
            index,
            modifierType,
            name,
          },
        })
      }
    />
  </label>
);

const Modifiers = ({ node, ...props }) => {
  const [
    notModifier,
    approximateModifier,
    caseModifier,
    changeByAmount,
    ChangeByPercentage,
    characterMatch,
    ChangeByExactAmount,
    ChangeByExactPercentage,
  ] = BusinessRuleOperationModifiers;

  const businessRule = node.operation.typeInformation[1].typeValue?.value;

  const isNotModifierSelected = node.operation.typeInformation.some(
    (ti) => ti.typeHierarchyLevel === 2
  );

  const isApproximateModifierSelected = node.operation.typeInformation.find(
    (ti) => ti.typeHierarchyLevel >= 3 && ti.typeValue === 2
  );

  const isByAmount = node.operation.typeInformation[2]?.typeValue === 4;
  const isByPercentage = node.operation.typeInformation[2]?.typeValue === 5;
  const isByExactAmount = node.operation.typeInformation[2]?.typeValue === 7;
  const isByExactPercentage =
    node.operation.typeInformation[2]?.typeValue === 8;

  const caseInsensitiveModifierSelected = node.operation.typeInformation.some(
    (ti) => ti.typeHierarchyLevel === 4
  );
  const isCharacterMatchModifierSelected = node.operation.typeInformation.some(
    (ti) => ti.typeHierarchyLevel >= 3 && ti.typeValue === 6
  );

  if (businessRule === 1) {
    return (
      <>
        {props?.hasModifier ? null : (
          <FormControl>
            <ModifierCheckBox
              selected={isApproximateModifierSelected}
              modifierType={approximateModifier?.value}
              node={node}
              {...props}
            >
              Approximate Match
            </ModifierCheckBox>
          </FormControl>
        )}

        {props?.hasModifier ? null : (
          <FormControl>
            <ModifierCheckBox
              selected={isCharacterMatchModifierSelected}
              modifierType={characterMatch?.value}
              node={node}
              {...props}
            >
              Character Match
            </ModifierCheckBox>
          </FormControl>
        )}

        <FormControl>
          <ModifierCheckBox
            selected={isNotModifierSelected}
            modifierType={notModifier?.value}
            node={node}
            {...props}
          >
            Modify with <strong>NOT</strong>
          </ModifierCheckBox>
        </FormControl>

        <FormControl>
          <ModifierCheckBox
            selected={caseInsensitiveModifierSelected}
            modifierType={caseModifier?.value}
            node={node}
            {...props}
          >
            Case Insensitive Match
          </ModifierCheckBox>
        </FormControl>
      </>
    );
  }

  if (
    !props.isReconciliationRule &&
    businessRule !== 13 &&
    businessRule !== 16 &&
    businessRule !== 17
  ) {
    return (
      <>
        <FormControl>
          <ModifierCheckBox
            selected={isNotModifierSelected}
            modifierType={notModifier?.value}
            node={node}
            {...props}
          >
            Modify with <strong>NOT</strong>
          </ModifierCheckBox>
        </FormControl>

        <FormControl>
          <ModifierCheckBox
            selected={caseInsensitiveModifierSelected}
            modifierType={caseModifier?.value}
            node={node}
            {...props}
          >
            Case Insensitive Match
          </ModifierCheckBox>
        </FormControl>
      </>
    );
  }

  if (props?.isReconciliationRule) {
    return (
      <>
        <FormControl>
          <ModifierCheckBox
            selected={isByAmount}
            modifierType={changeByAmount?.value}
            node={node}
            name="ByAnAmount"
            {...props}
          >
            By an Amount
          </ModifierCheckBox>
        </FormControl>

        <FormControl>
          <ModifierCheckBox
            selected={isByPercentage}
            modifierType={ChangeByPercentage?.value}
            node={node}
            name="ByAPercentage"
            {...props}
          >
            By a Percentage
          </ModifierCheckBox>
        </FormControl>

        <FormControl>
          <ModifierCheckBox
            selected={isByExactAmount}
            modifierType={ChangeByExactAmount?.value}
            node={node}
            name="ByExactAmount"
            {...props}
          >
            By a Exact Amount
          </ModifierCheckBox>
        </FormControl>

        <FormControl>
          <ModifierCheckBox
            selected={isByExactPercentage}
            modifierType={ChangeByExactPercentage?.value}
            node={node}
            name="ByExactPercentage"
            {...props}
          >
            By Exact Percentage
          </ModifierCheckBox>
        </FormControl>
      </>
    );
  }

  return null;
};

const OperationComponent = ({
  node,
  dispatch,
  index,
  isReconciliationRule,
  isEditing,
}) => {
  const isEditingRecon = isReconciliationRule && isEditing;
  const hasModifier =
    node?.source?.typeInformation.find((ti) => ti?.typeHierarchyLevel === 2)
      ?.typeValue ||
    node?.target?.typeInformation.find((ti) => ti?.typeHierarchyLevel === 3)
      ?.typeValue ||
    node?.target?.typeInformation.find((ti) => ti?.typeHierarchyLevel === 4)
      ?.typeValue;

  const acceptableOperations = hasModifier?.acceptableOperations;

  const Operations = BusinessRuleOperations.filter((item) =>
    acceptableOperations?.includes(item?.value)
  );

  // is Source Count - don't show modifiers
  const hasCountModifier =
    node?.source?.typeInformation.find((ti) => ti?.typeHierarchyLevel === 2)
      ?.typeValue?.value === 3;

  return (
    <>
      <NodeBody>
        <FormControl style={{ minWidth: "200px" }}>
          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`operations`}
            id={`operations`}
            inputId={`operationsSelect-input`}
            instanceId={`operationsSelect-instance`}
            label="Operations"
            menuPortalTarget={document.body}
            options={
              hasModifier
                ? Operations
                : BusinessRuleOperations.map((o) => {
                    if (isEditingRecon) {
                      return {
                        label: o?.label,
                        value: o?.value,
                        isDisabled:
                          isEditingRecon && o?.value !== 14 && o?.value !== 15,
                      };
                    } else {
                      return {
                        label: o?.label,
                        value: o?.value,
                      };
                    }
                  })
            }
            placeholder={`Operations`}
            value={node.operation.typeInformation[1]?.typeValue}
            onChange={(e) =>
              dispatch({
                type: "UPDATE_OPERATION_TYPE",
                payload: { node: node, value: e, index: index },
              })
            }
          />
        </FormControl>
        {!hasCountModifier && (
          <Modifiers
            node={node}
            dispatch={dispatch}
            index={index}
            isReconciliationRule={isReconciliationRule}
            hasModifier={hasModifier}
          />
        )}
      </NodeBody>
    </>
  );
};

export default OperationComponent;
