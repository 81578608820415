import React, { useState, useEffect, memo } from "react";
import Card from "../../components/Card";
import {
  Container,
  Button,
  Box,
  Typography,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { MdStar, MdStarBorder } from "react-icons/md";
import { toast } from "react-toastify";
import { useApi } from "../../api/useApi";
import {
  allOwnedWorkspaces,
  allSharedWorkspaces,
} from "../../api/workspaceQueries";
import {
  updateWorkspace,
  updateWorkspaceEnabled,
  updateWorkspaceFavorite,
} from "../../api/workspaceMutations";
import Permissions from "../../components/Form/PermissionsWithoutFormik";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { useHistory } from "react-router-dom";
import { workSpacesById, workSpaces } from "../../common/paths";
import { trackCustomEvent } from "../../appInsights";
import {
  generateId,
  generateContentName,
} from "../../common/helpers/contentManagement";

const ImportData = memo(
  ({
    openImport,
    handleCloseImport,
    importData,
    setImportData,
    importName,
    setImportName,
    importDesc,
    setImportDesc,
    createPermissions,
    setCreatePermissions,
    handleImport,
  }) => {
    return (
      <Dialog open={openImport} onClose={handleCloseImport}>
        <DialogTitle>Import Workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Paste the JSON export of your workspace here. Give your workspace a
            new Name & Description if desired. Select shared permissions if any
            are applicable.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="importData"
            label="JSON Data"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={importData}
            onChange={(e) => setImportData(e.target.value)}
          />

          <TextField
            margin="dense"
            label="Name Override"
            variant="outlined"
            fullWidth
            value={importName}
            onChange={(e) => setImportName(e.target.value)}
          />
          <TextField
            label="Description Override"
            variant="outlined"
            fullWidth
            multiline
            margin="dense"
            rows={4}
            value={importDesc}
            onChange={(e) => setImportDesc(e.target.value)}
          />
          <Permissions
            callFn={setCreatePermissions}
            permissions={createPermissions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImport} color="primary">
            Cancel
          </Button>
          <Button onClick={handleImport} color="secondary">
            Import and Overwrite
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const CloneData = memo(
  ({
    openClone,
    handleCloseImport,
    importName,
    setImportName,
    importDesc,
    setImportDesc,
    createPermissions,
    setCreatePermissions,
    handleClone,
  }) => {
    return (
      <Dialog open={openClone} onClose={handleCloseImport}>
        <DialogTitle>Clone Workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clone the workspace named {openClone?.name}. Give your workspace a
            New Name & Description if desired. Select shared permissions if any
            are applicable.
          </DialogContentText>
          <hr />
          Original Name: {openClone?.name}
          <TextField
            margin="dense"
            label="Name Override"
            variant="outlined"
            fullWidth
            value={importName}
            onChange={(e) => setImportName(e.target.value)}
          />
          Original Description: {openClone?.description}
          <TextField
            label="Description Override"
            variant="outlined"
            fullWidth
            multiline
            margin="dense"
            rows={4}
            value={importDesc}
            onChange={(e) => setImportDesc(e.target.value)}
          />
          <Permissions
            callFn={setCreatePermissions}
            permissions={createPermissions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImport} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClone} color="secondary">
            Create Clone
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const WorkspacesMain = () => {
  const Body = React.memo(() => {
    let history = useHistory();

    const { control, handleSubmit, reset } = useForm();

    const [open, setOpen] = useState(false);
    const [workspaceToDelete, setWorkspaceToDelete] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [importData, setImportData] = useState("");
    const [importName, setImportName] = useState("");
    const [importDesc, setImportDesc] = useState("");
    const [openImport, setOpenImport] = useState(false);
    const [openClone, setOpenClone] = useState(null);
    const [createPermissions, setCreatePermissions] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    const handleOpenImport = () => setOpenImport(true);
    const handleOpenClone = (workspace) => setOpenClone(workspace);
    const handleCloseImport = () => setOpenImport(false);
    const handleTabChange = (event, newIndex) => {
      setTabIndex(newIndex);
    };

    const [
      {
        loading: workspacesLoading,
        errors: workspacesErrors,
        data: workspacesData,
      },
      getWorkspaces,
    ] = useApi();

    const [
      {
        loading: workspacesSharedLoading,
        errors: workspacesSharedErrors,
        data: workspacesSharedData,
      },
      getWorkspacesShared,
    ] = useApi(allSharedWorkspaces, {
      $order: {
        name: "ASC",
      },
    });

    useEffect(() => {
      if (tabIndex === 0) {
        getWorkspaces({
          query: allOwnedWorkspaces,
          variables: {
            $order: {
              name: "ASC",
            },
          },
        });
      } else if (tabIndex === 1) {
        getWorkspacesShared({
          query: allSharedWorkspaces,
          variables: {
            $order: {
              name: "ASC",
            },
          },
        });
      }
    }, [tabIndex]);

    const [
      {
        loading: createWorkspaceLoading,
        errors: createWorkspaceErrors,
        data: createWorkspaceData,
      },
      createWorkspace,
    ] = useApi();

    useEffect(() => {
      if (createWorkspaceData && !createWorkspaceErrors) {
        trackCustomEvent(
          "update",
          "workspace",
          createWorkspaceData?.id || "unknown-id",
          "workspace update successful"
        );

        history.push(
          createWorkspaceData?.updateWorkspace?.id
            ? workSpacesById(createWorkspaceData?.updateWorkspace?.id)
            : workSpaces()
        );
      }
    }, [createWorkspaceData, history]);

    const [
      {
        loading: updateWorkspaceEnabledLoading,
        errors: updateWorkspaceEnabledErrors,
        data: updateWorkspaceEnabledData,
      },
      updateWorkspaceEnabledFn,
    ] = useApi();

    const [
      {
        loading: updateWorkspaceFavoriteLoading,
        errors: updateWorkspaceFavoriteErrors,
        data: updateWorkspaceFavoriteData,
      },
      updateWorkspaceFavoriteFn,
    ] = useApi();

    useEffect(() => {
      if (updateWorkspaceEnabledData && !updateWorkspaceEnabledErrors) {
        trackCustomEvent(
          "remove",
          "workspace",
          updateWorkspaceEnabledData?.updateWorkspaceEnabled?.id ||
            "unknown-id",
          "workspace removal successful"
        );

        if (tabIndex === 0) {
          getWorkspaces({
            query: allOwnedWorkspaces,
            variables: {
              $order: {
                name: "ASC",
              },
            },
          });
        } else if (tabIndex === 1) {
          getWorkspacesShared({
            query: allSharedWorkspaces,
            variables: {
              $order: {
                name: "ASC",
              },
            },
          });
        }
      }
    }, [getWorkspaces, tabIndex]);

    useEffect(() => {
      if (updateWorkspaceFavoriteData && !updateWorkspaceFavoriteErrors) {
        if (tabIndex === 0) {
          getWorkspaces({
            query: allOwnedWorkspaces,
            variables: {
              $order: {
                name: "ASC",
              },
            },
          });
        } else if (tabIndex === 1) {
          getWorkspacesShared({
            query: allSharedWorkspaces,
            variables: {
              $order: {
                name: "ASC",
              },
            },
          });
        }
      }
    }, [updateWorkspaceFavoriteData, tabIndex]);

    useEffect(() => {
      if (updateWorkspaceEnabledData && !updateWorkspaceEnabledErrors) {
        trackCustomEvent(
          "remove",
          "workspace",
          updateWorkspaceEnabledData?.updateWorkspaceEnabled?.id ||
            "unknown-id",
          "workspace removal successful"
        );
        if (tabIndex === 0) {
          getWorkspaces({
            query: allOwnedWorkspaces,
            variables: {
              $order: {
                name: "ASC",
              },
            },
          });
        } else if (tabIndex === 1) {
          getWorkspacesShared({
            query: allSharedWorkspaces,
            variables: {
              $order: {
                name: "ASC",
              },
            },
          });
        }
      }
    }, [updateWorkspaceEnabledData, tabIndex]);

    const workspaces = workspacesData?.allOwnedWorkspaces ?? [];
    const workspacesCount = workspaces?.length;
    const workspacesShared = workspacesSharedData?.allSharedWorkspaces ?? [];
    const workspacesSharedCount = workspacesShared?.length;

    const onSubmit = (data) => {
      const newWorkspace = {
        workSpaceModel: {
          id: 0, // Generate unique workspaceId
          name: data.name,
          description: data.description,
          configuration: JSON.stringify({
            widgets: [],
            sections: [],
          }),
          makeFav: false,
          enabled: true,
          permissions: createPermissions,
        },
      };

      createWorkspace({
        query: updateWorkspace,
        variables: newWorkspace,
      });

      reset();
      setDialogOpen(false);
    };

    const handleDelete = (workspace) => {
      setWorkspaceToDelete(workspace);
      setOpen(true);
    };

    const confirmDelete = () => {
      const enableWorkspace = {
        workSpaceId: workspaceToDelete.id,
        enabled: false,
      };

      updateWorkspaceEnabledFn({
        query: updateWorkspaceEnabled,
        variables: enableWorkspace,
      });

      setOpen(false);
      setWorkspaceToDelete(null);
    };

    const handleClose = () => {
      setOpen(false);
      setWorkspaceToDelete(null);
    };

    const handleFavorite = (workspace) => {
      const favoriteWorkspace = {
        workSpaceId: workspace?.id,
        favorite: !workspace?.makeFav,
      };

      updateWorkspaceFavoriteFn({
        query: updateWorkspaceFavorite,
        variables: favoriteWorkspace,
      });
    };

    const handleDialogOpen = () => {
      setDialogOpen(true);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const exportWorkspace = (workspace) => {
      navigator.clipboard
        .writeText(
          JSON.stringify({
            name: workspace?.name,
            configuration: workspace?.configuration,
            description: workspace?.description,
          })
        )
        .then(
          function () {
            toast.success("Workspaces Copied to Clipboard", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          },
          function (err) {
            toast.error("Failed To Copy", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        );
    };

    const handleImport = () => {
      // Parse the import data string into an object
      const parseImport = JSON.parse(importData);

      // Helper function to determine the value for a field
      const getFieldValue = (importValue, importDataValue) => {
        if (importValue !== undefined && importValue.trim() !== "") {
          return importValue;
        } else if (importDataValue !== undefined) {
          return importDataValue;
        } else {
          return ""; // Default to empty string if both are undefined or empty
        }
      };

      // Ensure configuration is a stringified JSON
      const configuration =
        typeof parseImport.configuration === "string"
          ? parseImport.configuration
          : JSON.stringify(parseImport.configuration);

      // Construct the newWorkspace object
      const newWorkspace = {
        workSpaceModel: {
          id: 0, // Generate unique workspaceId
          name: getFieldValue(importName, parseImport?.name),
          description: getFieldValue(importDesc, parseImport?.description),
          configuration: configuration, // Ensure configuration is a string
          makeFav: false,
          enabled: true,
          permissions: createPermissions,
        },
      };

      createWorkspace({
        query: updateWorkspace,
        variables: newWorkspace,
      });

      reset();
      setDialogOpen(false);

      handleCloseImport();
    };

    const handleClone = () => {
      // Parse the import data string into an object
      const parseImport = openClone;

      // Helper function to determine the value for a field
      const getFieldValue = (importValue, importDataValue) => {
        if (importValue !== undefined && importValue.trim() !== "") {
          return importValue;
        } else if (importDataValue !== undefined) {
          return importDataValue;
        } else {
          return ""; // Default to empty string if both are undefined or empty
        }
      };

      // Ensure configuration is a stringified JSON
      const configuration =
        typeof parseImport.configuration === "string"
          ? parseImport.configuration
          : JSON.stringify(parseImport.configuration);

      // Construct the newWorkspace object
      const newWorkspace = {
        workSpaceModel: {
          id: 0, // Generate unique workspaceId
          name: getFieldValue(importName, parseImport?.name),
          description: getFieldValue(importDesc, parseImport?.description),
          configuration: configuration, // Ensure configuration is a string
          makeFav: false,
          enabled: true,
          permissions: createPermissions,
        },
      };

      createWorkspace({
        query: updateWorkspace,
        variables: newWorkspace,
      });

      reset();
      setDialogOpen(false);

      handleCloseImport();
    };

    if (workspacesErrors)
      return (
        <Box
          sx={{
            marginTop: "2rem",
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        >
          <ErrorMessages errors={workspacesErrors} />
        </Box>
      );

    if (workspacesSharedErrors)
      return (
        <Box
          sx={{
            marginTop: "2rem",
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        >
          <ErrorMessages errors={workspacesSharedErrors} />
        </Box>
      );

    const workspaceList = tabIndex === 0 ? workspaces : workspacesShared;

    return (
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenImport}
            sx={{
              background: "rgb(0, 159, 212)",
              "&:hover": {
                backgroundColor: "#999",
              },
              marginRight: "1rem",
            }}
          >
            Import
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogOpen}
            sx={{
              background: "rgb(0, 159, 212)",
              "&:hover": {
                backgroundColor: "#999",
              },
            }}
          >
            {createWorkspaceLoading ? <CircularProgress /> : "Add Workspace"}
          </Button>
        </Box>

        {updateWorkspaceEnabledErrors && (
          <ErrorMessages errors={updateWorkspaceEnabledErrors} />
        )}

        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab
            label={`My Workspaces ${
              workspacesCount ? `(${workspacesCount})` : ""
            }`}
          />
          <Tab
            label={`Shared with me ${
              workspacesSharedCount ? `(${workspacesSharedCount})` : ""
            }`}
          />
        </Tabs>

        <Box mt={2}>
          <>
            {workspacesLoading || workspacesSharedLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : !workspaceList?.length ? (
              <Container maxWidth="md">
                <Box mt={5} component={Paper} p={3}>
                  <Typography variant="h4" gutterBottom>
                    Welcome to Workspaces
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Workspaces empower you to craft your unique data narratives
                    by offering a flexible and customizable environment.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Start by Creating Your Workspace:</strong> Begin by
                    giving your workspace a meaningful name and a brief
                    description. This helps in organizing your data stories and
                    provides context for future reference.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Workspace View:</strong> Once your workspace is set
                    up, you’ll enter the workspace view. Here, you can start
                    adding widgets or sections. Sections act as visual
                    organizers within your workspace, allowing you to group
                    related widgets together for better clarity and structure.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Add Widgets:</strong> Choose from a variety of
                    pre-defined widgets or dive into our specialized components
                    based on available APIs to explore and display the data that
                    matters most to you.
                  </Typography>
                  <Box mt={4}>
                    <Typography variant="h6">
                      Create Your First Workspace
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Workspace Name"
                                variant="outlined"
                                fullWidth
                                required
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Description"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                required
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h3>Permissions</h3>
                          <Permissions
                            callFn={setCreatePermissions}
                            permissions={createPermissions}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            Create Workspace
                          </Button>
                          <Button
                            onClick={handleOpenImport}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ marginTop: "1rem" }}
                          >
                            Import Workspace
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>

                <ImportData
                  openImport={openImport}
                  handleCloseImport={handleCloseImport}
                  importData={importData}
                  setImportData={setImportData}
                  importName={importName}
                  setImportName={setImportName}
                  importDesc={importDesc}
                  setImportDesc={setImportDesc}
                  createPermissions={createPermissions}
                  setCreatePermissions={setCreatePermissions}
                  handleImport={handleImport}
                />
                <CloneData
                  openClone={openClone}
                  handleCloseImport={handleCloseImport}
                  importData={openClone}
                  setImportData={setImportData}
                  importName={importName}
                  setImportName={setImportName}
                  importDesc={importDesc}
                  setImportDesc={setImportDesc}
                  createPermissions={createPermissions}
                  setCreatePermissions={setCreatePermissions}
                  handleClone={handleClone}
                />
              </Container>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Workspace Name</TableCell>
                      <TableCell>Number of Widgets</TableCell>
                      <TableCell>Owner</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workspaceList.map((workspace) => (
                      <TableRow key={workspace.id}>
                        <TableCell>
                          <Link
                            to={`/workspaces/${workspace.id}`}
                            underline="none"
                          >
                            {workspace.name}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {
                            JSON.parse(workspace?.configuration)?.widgets
                              ?.length
                          }
                        </TableCell>
                        <TableCell>{workspace.owner}</TableCell>
                        <TableCell>
                          {updateWorkspaceFavoriteLoading ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              title="Toggle as Homepage Workspace"
                              onClick={() => handleFavorite(workspace)}
                            >
                              {workspace.makeFav ? (
                                <MdStar
                                  style={{
                                    color: "rgb(0, 159, 212)",
                                    fontSize: "inherit",
                                  }}
                                />
                              ) : (
                                <MdStarBorder />
                              )}
                            </IconButton>
                          )}

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => exportWorkspace(workspace)}
                            sx={{
                              marginLeft: "1rem",
                              backgroundColor: "rgb(18, 17, 53)",
                              "&:hover": {
                                backgroundColor: "rgba(18, 17, 53, 0.8)",
                              },
                            }}
                          >
                            Export
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleOpenClone(workspace)}
                            sx={{
                              marginLeft: "1rem",
                              backgroundColor: "rgb(18, 17, 53)",
                              "&:hover": {
                                backgroundColor: "rgba(18, 17, 53, 0.8)",
                              },
                            }}
                          >
                            Clone
                          </Button>

                          {updateWorkspaceEnabledLoading ? (
                            <CircularProgress />
                          ) : tabIndex === 0 ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDelete(workspace)}
                              sx={{
                                marginLeft: "1rem",
                                backgroundColor: "rgb(18, 17, 53)",
                                "&:hover": {
                                  backgroundColor: "rgba(18, 17, 53, 0.8)",
                                },
                              }}
                            >
                              Remove
                            </Button>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!workspaceList.length &&
              !workspacesLoading &&
              !workspacesSharedLoading && (
                <Container maxWidth="md">
                  <Box mt={5} display="flex" justifyContent="center">
                    No Workspaces Found
                  </Box>
                </Container>
              )}
          </>
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the workspace "
              {workspaceToDelete?.name}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Add New Workspace</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Workspace Name"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              id={generateId("button", "create-workspace")}
              data-ai-content-name={generateContentName("workspaces create")}
            >
              {createWorkspaceLoading ? <CircularProgress /> : "Add Workspace"}
            </Button>
          </DialogActions>
        </Dialog>
        <ImportData
          openImport={openImport}
          handleCloseImport={handleCloseImport}
          importData={importData}
          setImportData={setImportData}
          importName={importName}
          setImportName={setImportName}
          importDesc={importDesc}
          setImportDesc={setImportDesc}
          createPermissions={createPermissions}
          setCreatePermissions={setCreatePermissions}
          handleImport={handleImport}
        />
        <CloneData
          openClone={openClone}
          handleCloseImport={handleCloseImport}
          importData={openClone}
          setImportData={setImportData}
          importName={importName}
          setImportName={setImportName}
          importDesc={importDesc}
          setImportDesc={setImportDesc}
          createPermissions={createPermissions}
          setCreatePermissions={setCreatePermissions}
          handleClone={handleClone}
        />
      </Container>
    );
  });

  return (
    <Card
      title={"Workspaces"}
      titleDescription={"craft your data narrative"}
      body={Body}
    />
  );
};

export default WorkspacesMain;
