import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseRlrFailureDetailsByPK = () => {
  const { rlrFailureDetailsByPK, setRlrFailureDetailsByPK } =
    useContext(SocketContext);

  return { rlrFailureDetailsByPK, setRlrFailureDetailsByPK };
};

export default UseRlrFailureDetailsByPK;
