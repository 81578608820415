import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  StyledSelect,
  FormControl,
  StyledInput,
} from "../../Form/FormControls";
import { ColumnModifiers } from "./buildingBlocks";
import { VscBeaker } from "react-icons/vsc";
import Button from "../../Button";
import { Scrollbars } from "react-custom-scrollbars";
import MarkdownFetcher from "../../../views/FeedsPage/transformerFunctions/MarkDownFetcher";
import Modal from "../../Modal";
import { MdOutlineHelp } from "react-icons/md";

const NodeBody = styled.div`
  padding: 0.5em;
  flex: 1;
  background: rgba(0, 0, 0, 0.03);
`;

const SourceComponent = ({
  node,
  dispatch,
  index,
  enableTest,
  testRow,
  goodTestParse,
  isFilter,
}) => {
  //Show Helper
  const [showHelper, setShowHelper] = useState(false);

  const setHelper = (helperName) => {
    setShowHelper(helperName);
  };

  const SourceOptions = [{ label: "Column", value: 1 }];

  let testRowDataOptions = [];

  try {
    if (testRow && goodTestParse) {
      testRowDataOptions = JSON.parse(testRow);
    }
  } catch (e) {
    testRowDataOptions = [];
  }

  const testOptions = testRowDataOptions.map((to, i) => {
    return {
      label: to,
      value: i,
    };
  });

  const currentOperation = node.operation.typeInformation[1].typeValue?.value;

  const acceptableOperationsModifiers = [1, 2, 3, 4, 5, 14, 15];

  const showModifier = currentOperation
    ? acceptableOperationsModifiers.includes(currentOperation)
    : null;

  const sourceModifier = node.source.typeInformation.find(
    (ti) => ti?.typeHierarchyLevel === 2
  )?.typeValue;

  const [enableColumnAttribute, setEnableColumnAttribute] = useState(
    node.source.typeInformation.find((ti) => ti?.typeHierarchyLevel === 2)
      ? true
      : false
  );

  useEffect(() => {
    if (enableColumnAttribute) {
      if (sourceModifier?.value !== 4 && sourceModifier?.value !== 3) {
        dispatch({
          type: "UPDATE_MODIFIER_SOURCE",
          payload: {
            node: node,
            value: sourceModifier ?? ColumnModifiers[0],
            index: index,
            fragmentValue: node?.source?.typeInformation[2]?.fragmentValue,
          },
        });
      }
    } else {
      dispatch({
        type: "REMOVE_MODIFIER_SOURCE",
        payload: {
          node: node,
          value: ColumnModifiers[0],
          index: index,
        },
      });
    }
    // eslint-disable-next-line
  }, [enableColumnAttribute, dispatch, sourceModifier]);

  // Aggregation code

  // Aggregation logic that runs only if sourceModifier && sourceModifier?.value === 4
  const renderAggregationFields = () => {
    const fragmentValue = node.source.typeInformation[1].fragmentValue;

    // Parse the fragment value into arrays of arrays directly
    function parseToNestedArray(input) {
      if (!input) return [[], []];

      // Step 1: Add double quotes around words or phrases that need to be strings
      const jsonCompatibleInput = input
        .replace(/\[/g, '["')
        .replace(/\]/g, '"]')
        .replace(/,(\s*)\[/g, ",[") // clean up extra quotes around brackets
        .replace(/"\[/g, "[") // remove any opening quotes before brackets
        .replace(/\]"/g, "]") // remove any closing quotes after brackets
        .replace(/"\s*,\s*"/g, '","'); // retain commas between quoted strings

      // Step 2: Parse the adjusted string to create a JavaScript array
      try {
        let parsedArray = JSON.parse(jsonCompatibleInput);

        // Step 3: Ensure the structure has at least two arrays for Group_By and Sum_By
        if (!Array.isArray(parsedArray)) parsedArray = [[], []];
        if (!Array.isArray(parsedArray[0])) parsedArray[0] = [];
        if (!Array.isArray(parsedArray[1])) parsedArray[1] = [];

        // Step 4: Filter out empty strings or invalid entries within Group_By and Sum_By
        parsedArray[0] = parsedArray[0].filter(
          (item) => Array.isArray(item) && item[0].trim() !== ""
        );
        parsedArray[1] = parsedArray[1].filter(
          (item) => Array.isArray(item) && item[0].trim() !== ""
        );

        return parsedArray;
      } catch (error) {
        console.error("Parsing failed:", error.message);
        return [[], []]; // Default to two empty arrays if parsing fails
      }
    }

    const parsedValues = parseToNestedArray(fragmentValue);

    const groupByColumns = parsedValues[0] || [];
    const sumByColumns = parsedValues[1] || [];

    function stringifyFromNestedArray(array) {
      // Convert array to JSON string
      let jsonString = JSON.stringify(array);

      // Remove all quotes around each string inside the nested arrays
      jsonString = jsonString.replace(/"\b([^"]*)\b"/g, "$1");

      return jsonString;
    }

    // Function to update the fragment value in the state
    const updateFragmentValue = (updatedGroupBy, updatedSumBy) => {
      const combinedArray = [updatedGroupBy, updatedSumBy];
      const newValue = stringifyFromNestedArray(combinedArray);

      dispatch({
        type: "UPDATE_SOURCE_NAME_SUM_MODIF",
        payload: { node, value: newValue, index },
      });
    };

    // Function to add a new "Group By" column
    const addGroupByColumn = () => {
      const updatedGroupBy = [
        ...groupByColumns,
        [`Group_By_Column_${groupByColumns.length + 1}`],
      ];
      updateFragmentValue(updatedGroupBy, sumByColumns);
    };

    // Save adding multiple sum for later
    // Function to add a new "Sum By" column
    // const addSumByColumn = () => {
    //   const updatedSumBy = [
    //     ...sumByColumns,
    //     [`Sum_By_Column_${sumByColumns.length + 1}`],
    //   ];
    //   updateFragmentValue(groupByColumns, updatedSumBy);
    // };

    // Function to handle input changes for a specific column in Group By or Sum By
    const handleColumnChange = (type, columnIndex, newValue) => {
      // Replace spaces with underscores, and default to "_" if the result is empty
      const sanitizedValue = newValue.replace(/\s+/g, "_") || "_";

      if (type === "groupBy") {
        const updatedGroupBy = [...groupByColumns];
        updatedGroupBy[columnIndex] = [sanitizedValue];
        updateFragmentValue(updatedGroupBy, sumByColumns);
      } else if (type === "sumBy") {
        const updatedSumBy = [...sumByColumns];
        updatedSumBy[columnIndex] = [sanitizedValue];
        updateFragmentValue(groupByColumns, updatedSumBy);
      }
    };

    const removeColumn = (type, columnIndex) => {
      if (type === "groupBy") {
        const updatedGroupBy = groupByColumns.filter(
          (_, i) => i !== columnIndex
        );
        updateFragmentValue(updatedGroupBy, sumByColumns);
      } else if (type === "sumBy") {
        const updatedSumBy = sumByColumns.filter((_, i) => i !== columnIndex);
        updateFragmentValue(groupByColumns, updatedSumBy);
      }
    };

    return (
      <div style={{ marginBottom: "1rem" }}>
        <h4>Group By Columns</h4>
        {groupByColumns.map((column, i) => (
          <FormControl
            key={`groupBy-${i}`}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledInput
              type="text"
              value={column[0] || ""}
              placeholder={`Group_By_Column_${i + 1}`}
              onChange={(e) => handleColumnChange("groupBy", i, e.target.value)}
            />
            {i > 0 && (
              <button
                onClick={() => removeColumn("groupBy", i)}
                style={{ marginLeft: "0.5em" }}
              >
                x
              </button>
            )}
          </FormControl>
        ))}
        <Button onClick={addGroupByColumn}>Add Group By Column</Button>
        {sourceModifier?.value === 4 && (
          <>
            <h4 style={{ marginTop: "1rem" }}>Sum By Column</h4>
            {sumByColumns.map((column, i) => (
              <FormControl
                key={`sumBy-${i}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledInput
                  type="text"
                  value={column[0] || ""}
                  placeholder={`Sum_By_Column ${i + 1}`}
                  onChange={(e) =>
                    handleColumnChange("sumBy", i, e.target.value)
                  }
                />
                {i > 0 && (
                  <button
                    onClick={() => removeColumn("sumBy", i)}
                    style={{ marginLeft: "0.5em" }}
                  >
                    x
                  </button>
                )}
              </FormControl>
            ))}
          </>
        )}

        {/* <Button onClick={addSumByColumn} style={{ marginBottom: "1rem" }}>
          Add Sum By Column
        </Button> */}
      </div>
    );
  };

  return (
    <>
      <NodeBody>
        <FormControl>
          <StyledSelect
            aria-label="fragmentColumnSelect"
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`source`}
            id={`source`}
            inputId={`sourceSelect-input`}
            instanceId={`sourceSelect-instance`}
            label="Source"
            options={SourceOptions}
            placeholder={`Select Source Type`}
            menuPortalTarget={document.body}
            value={node.source.typeInformation[1].typeValue}
            onChange={(e) =>
              dispatch({
                type: "UPDATE_SOURCE_TYPE",
                payload: { node: node, value: e, index: index },
              })
            }
          />
        </FormControl>

        {(sourceModifier && sourceModifier?.value === 4) ||
        (sourceModifier && sourceModifier?.value === 3) ? (
          renderAggregationFields()
        ) : (
          <FormControl>
            <StyledInput
              type="text"
              name="source-value"
              value={node.source.typeInformation[1].fragmentValue}
              placeholder="Column Name"
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_SOURCE_NAME",
                  payload: { node: node, value: e.target?.value, index: index },
                })
              }
            />
          </FormControl>
        )}

        {showModifier && (
          <>
            <FormControl>
              <label>
                Use Modifier
                <input
                  type="checkbox"
                  checked={enableColumnAttribute}
                  onChange={() => {
                    setEnableColumnAttribute((prevState) => !prevState);
                  }}
                />
              </label>
            </FormControl>
          </>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex",
              fontSize: ".8rem",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setHelper("MODIFIERS");
            }}
          >
            Modifiers Help
            <MdOutlineHelp
              style={{
                marginLeft: ".5rem",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
              title="Modifiers Help"
            />
          </div>
        </div>

        {enableTest ? (
          <FormControl>
            <VscBeaker /> Test Value{" "}
            <StyledSelect
              aria-label="fragmentColumnSelect"
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`testInfo`}
              id={`testInfo`}
              inputId={`testInfoSelect-input`}
              instanceId={`testInfoSelect-instance`}
              label="Source"
              options={testOptions}
              placeholder={`Select Test Value`}
              value={node.source.testInfo}
              menuPortalTarget={document.body}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_TEST_VALUE",
                  payload: { node: node, value: e, index: index },
                })
              }
            />
          </FormControl>
        ) : null}

        {enableColumnAttribute && showModifier ? (
          <>
            <FormControl>
              <StyledSelect
                aria-label="fragmentColumnSelect"
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`source`}
                id={`source`}
                inputId={`sourceSelect-input`}
                instanceId={`sourceSelect-instance`}
                label="Source"
                options={
                  isFilter
                    ? ColumnModifiers.filter(
                        (cm) => cm.label !== "Count" && cm.label !== "Sum"
                      )
                    : ColumnModifiers
                }
                placeholder={`Select Source Modifier`}
                value={sourceModifier}
                menuPortalTarget={document.body}
                onChange={(e) =>
                  dispatch({
                    type: "UPDATE_MODIFIER_SOURCE",
                    payload: { node: node, value: e, index: index },
                  })
                }
              />
            </FormControl>

            {node.source?.typeInformation[2]?.typeHierarchyLevel === 2 &&
            node.source?.typeInformation[2]?.typeValue?.value === 2 ? (
              <FormControl>
                <StyledInput
                  type="number"
                  name="variable-modifier-value"
                  value={node?.source?.typeInformation[2]?.fragmentValue}
                  placeholder="Modifier Value"
                  label="Number of days to add (to subtract, use a negative value)"
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_SOURCE_MODIFIER_VALUE",
                      payload: {
                        node: node,
                        value: e.target?.value,
                        index: index,
                      },
                    })
                  }
                />
              </FormControl>
            ) : null}
          </>
        ) : null}

        {showHelper ? (
          <Modal hide={() => setShowHelper(false)}>
            <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
              <div
                style={{
                  padding: "1rem",
                  background: "#fbfbfb",
                  marginBottom: "1rem",
                }}
              >
                <MarkdownFetcher
                  hideClose
                  MdFile={showHelper}
                  close={setShowHelper}
                />
              </div>
            </Scrollbars>
          </Modal>
        ) : null}
      </NodeBody>
    </>
  );
};

export default SourceComponent;
