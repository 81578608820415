import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseRLRReport = () => {
  const { rlrReport, setRlrReport } = useContext(SocketContext);

  return { rlrReport, setRlrReport };
};

export default UseRLRReport;
