import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useApi } from "../../../api/useApi";
import { availableDataSourcesBare } from "../../../api/dataSourceQueries";
import Loading from "./Loading";
import Error from "./Error";
import { Link } from "react-router-dom";
import { formatTime } from "../../../common/formats";
import FeedsList from "../../FeedsPage/FeedsList.js";

const DataSourceList = ({ data }) => {
  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>
      {data?.transfers?.length ? (
        <FeedsList widgetData={data} />
      ) : (
        "No Selected Transfers"
      )}
    </>
  );
};

export default DataSourceList;
