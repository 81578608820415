import React from "react";
import { useApi } from "../../../api/useApi";
import { allRecordLevelReportConfigurationSummaries } from "../../../api/reportQueries";
import PagedTable from "../../Table/PagedTable";
import StyledLink from "../../StyledLink";
import { dynamicViewsById, dynamicViewsEdit } from "../../../common/paths";
import TableButton from "../../Button/TableButton";
import { Link } from "react-router-dom";
import ErrorMessages from "../../Notifications/ErrorMessages";
import ActionWrapper from "../../ActionsWrapper";
import Card from "../../Card";
import { MdAdd } from "react-icons/md";
import { dynamicViewsNew } from "../../../common/paths";

const DynamicViewList = () => {
  const [{ loading, errors, data }, doFetch] = useApi();

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: allRecordLevelReportConfigurationSummaries,
        variables: {
          first: pageSize,
          after: cursor,
          where: {
            enabled: { eq: true },
          },
        },
      });
    },
    [doFetch]
  );

  const views = data?.allRecordLevelReportConfigurationSummaries?.nodes ?? [];

  const totalCount =
    data?.allRecordLevelReportConfigurationSummaries?.totalCount;
  const pageInfo = data?.allRecordLevelReportConfigurationSummaries?.pageInfo;

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      filter: true,
      accessor: (d) => d?.name,
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {" "}
            <StyledLink to={dynamicViewsById(original?.id)}>
              {original?.name}
            </StyledLink>
          </div>
        );
      },
    },
    {
      Header: "Rule Count",
      id: "ruleCount",
      filter: true,
      accessor: (d) => d?.ruleCount,
      Cell: ({ row: { original } }) => {
        return original?.ruleCount;
      },
    },
    {
      Header: "Source Count",
      id: "sourceCount",
      filter: true,
      accessor: (d) => d?.sourceCount,
      Cell: ({ row: { original } }) => {
        return original?.sourceCount;
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        return (
          <div style={{ textAlign: "right" }}>
            <Link to={dynamicViewsEdit(original?.id)}>
              <TableButton
                type="button"
                title="Manage"
                list="true"
                data-testid="manageDynamicView"
              >
                Manage
              </TableButton>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ padding: "1rem" }}>
      {errors ? <ErrorMessages errors={errors} /> : null}
      <div>
        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={views}
          columns={columnsData}
          defaultPageSize={50}
        />
      </div>
    </div>
  );
};

const Body = () => {
  return <DynamicViewList />;
};

//Actions Section of Widget
function Actions() {
  return (
    <>
      <ActionWrapper>
        <StyledLink data-testid="add-dynamicview" to={dynamicViewsNew}>
          <MdAdd />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const CardWrapper = () => {
  return (
    <Card
      title={"DYNAMIC VIEWS"}
      titleDescription={"CREATE REPORTS TO AGGREGATE EXCEPTIONS ACROSS SOURCES"}
      body={Body}
      actions={() => Actions()}
    />
  );
};

export default CardWrapper;
