import React, { useContext, useEffect } from "react";
import styled from "styled-components/macro";
import { AuthContext } from "../contexts/AuthContext";
import Spinner from "../components/Loaders/Spinner";
import BCALogo from "../components/Logos/BCALogo";
import SpinningLoader from "../components/Loaders/SpinningLoader";
import { Formik, Form } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
} from "../components/Form/FormControls";
import Spacer from "../components/Layout/Spacer";
import Button from "../components/Button";
import { MdWarning } from "react-icons/md";
import { updatePlatformConfiguration } from "../api/configurationMutations";
import { isProductConfigured } from "../api/configurationQueries";
import { useApi } from "../api/useApi";
import ErrorMessages from "../components/Notifications/ErrorMessages";
import { useHistory } from "react-router-dom";
import { welcome } from "../common/paths";

const HomeContainer = styled.div`
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FlexSplash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
`;

const MarketingContainer = styled.div`
  border: 4px solid #fff;
  max-width: 1080px;
  padding: 8px;
  color: #fff;
`;

const Login = styled.button`
  padding: 20px;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 50px;
  color: #ddd;
  background: #121135;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: "Source Sans Pro Semibold";
  border: 0;
`;

const LoginText = styled.span`
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
`;

const TagLineH3 = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-family: "Source Sans Pro Semibold";
  @media (max-width: 768px) {
    font-size: 31px;
  }
  @media (max-width: 593px) {
    font-size: 17px;
    text-align: center;
    margin-bottom: 5px;
  }
`;

const TagLine = styled.h3`
  margin: 0;
  letter-spacing: 1px;
  text-align: center;
  font-size: 31px;
  @media (max-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 593px) {
    font-size: 11px;
  }
`;

const HomeLogo = styled(BCALogo)`
  width: 21em;
  position: absolute;
  margin: 3em;
`;

const ConfigForm = styled.div`
  background: #fff;
  padding: 20px;
  max-width: 500px;
`;

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WarningIcon = styled.div`
  color: red;
  margin-right: 10px;
`;

export default function Homepage() {
  let history = useHistory();

  const [{ errors: isConfiguredErrors, loading: loadingConfig, data }] = useApi(
    isProductConfigured,
    null,
    { secure: false }
  );

  const [
    {
      errors: platformConfigurationDataErrors,
      loading: updatePlatformConfigureLoading,
      data: updatePlatformConfigurationData,
    },
    updateConfigurationData,
  ] = useApi();

  const { loginErrors, login, user } = useContext(AuthContext);

  //redirect on home to welcome
  useEffect(() => {
    if (user && !loadingConfig) {
      history.push(welcome());
    }
  }, [user, loadingConfig, history]);

  useEffect(() => {
    document.body.className = "home-page";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    if (updatePlatformConfigurationData && !platformConfigurationDataErrors) {
      history.push(welcome());
    }
  }, [
    updatePlatformConfigurationData,
    platformConfigurationDataErrors,
    history,
  ]);

  const submitForm = (values) => {
    updateConfigurationData({
      query: updatePlatformConfiguration,
      variables: { ...values },
    });
  };

  return (
    <HomeContainer>
      <HomeLogo />

      {isConfiguredErrors ? (
        <FlexSplash>
          <ConfigForm>
            <ErrorMessages errors={isConfiguredErrors} />
          </ConfigForm>
        </FlexSplash>
      ) : null}

      {loadingConfig ? (
        <FlexSplash>
          <ConfigForm>
            <SpinningLoader text="Loading Application Settings" />
          </ConfigForm>
        </FlexSplash>
      ) : null}

      {!loadingConfig && data?.isProductConfigured ? (
        <FlexSplash>
          <MarketingContainer>
            <TagLineH3>INNOVATIVE BUSINESS SOLUTIONS</TagLineH3>
            <TagLine>HELPING CLIENTS MANAGE DATA QUALITY</TagLine>
          </MarketingContainer>
          <Login type="button" onClick={login}>
            <LoginText>{"LOGIN"}</LoginText>
          </Login>
          {/* Login Error Message */}
          {loginErrors ? (
            <div>
              <WarningContainer>
                <WarningIcon>
                  <MdWarning />
                </WarningIcon>
                {loginErrors}
              </WarningContainer>
            </div>
          ) : null}
        </FlexSplash>
      ) : null}

      {!loadingConfig && !data?.isProductConfigured ? (
        <FlexSplash>
          <ConfigForm>
            <h3>Setup </h3>
            <p>
              In order to complete the application setup, we just need a few
              more details about your organization. Your application will
              restart after saving.
            </p>
            <Spacer />
            <Formik
              initialValues={{
                newDeploymentId: "",
              }}
              validate={(values) => {
                let errors = {};

                if (!values.newDeploymentId) {
                  errors.newDeploymentId = "Required";
                }
                return errors;
              }}
              onSubmit={(values) => submitForm(values)}
            >
              {({ isValid, isSubmitting }) => {
                return (
                  <Form>
                    <FormControl>
                      <StyledField
                        name={`newDeploymentId`}
                        type="text"
                        placeholder="Deployment ID"
                        label="Deployment ID"
                      />
                      <ErrorMessage name={`newDeploymentId`} />
                    </FormControl>
                    <FormActions>
                      {platformConfigurationDataErrors ? (
                        <ErrorMessages
                          errors={platformConfigurationDataErrors}
                        />
                      ) : null}
                      <FormControl>
                        <Button
                          type="submit"
                          disabled={updatePlatformConfigureLoading || !isValid}
                        >
                          {updatePlatformConfigureLoading ? (
                            <Spinner />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </FormControl>
                    </FormActions>
                  </Form>
                );
              }}
            </Formik>
          </ConfigForm>
        </FlexSplash>
      ) : null}
    </HomeContainer>
  );
}
