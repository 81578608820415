import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  FormControl,
  FormActions,
  FieldError,
  Label,
  StyledField,
  StyledSelect,
} from "../../components/Form/FormControls";
import Button from "../../components/Button";
import Spinner from "../../components/Loaders/Spinner";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { useApi } from "../../api/useApi";

import { Link } from "react-router-dom";
import {
  checkADGroupExists,
  setGroupActive,
} from "../../api/organizationMutations";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import useAllOrgs from "../../Hooks/useAllOrgs";
import SortTable from "../../components/Table/SortTable";
import { welcome, adminGroups } from "../../common/paths";

const SmallText = styled.div`
  font-size: 0.7rem;
  margin-bottom: 0.4rem;
`;

const OrgSelect = ({ setFieldValue }) => {
  const [{ stateOrgs, isLoading }] = useAllOrgs();
  const [selectedOrg, setSelectedOrg] = useState();

  useEffect(() => {
    if (selectedOrg && setFieldValue) {
      setFieldValue(`org`, selectedOrg);
    }
  }, [selectedOrg, setFieldValue]);

  if (isLoading) return <div>Loading Options</div>;

  return (
    <FormControl>
      <Label>Select Organization</Label>
      <StyledSelect
        className={`react-select-container`}
        classNamePrefix={`react-select`}
        name={`org`}
        id={`org`}
        inputId={`org-input`}
        instanceId={`org-instance`}
        label="Select Organization"
        options={stateOrgs ?? []}
        placeholder={`Select Organization`}
        value={selectedOrg}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        onChange={(e) => setSelectedOrg(e)}
      />
    </FormControl>
  );
};

const OrganizationComponent = ({ setShowAddGroup, welcome: welcomeParam }) => {
  const [{ loading, errors, data }, search] = useApi();

  //Add Group
  const [
    { loading: addGroupLoading, errors: addGroupErrors, data: addGroup },
    submitGroups,
  ] = useApi();

  const addGroupToPlatform = React.useCallback(
    (group) => {
      submitGroups({
        query: setGroupActive,
        variables: {
          groups: [group],
        },
      });
    },
    [submitGroups]
  );

  let history = useHistory();

  useEffect(() => {
    if (addGroup && !addGroupErrors) {
      if (welcomeParam) {
        history.push(welcome());
      } else {
        history.push(adminGroups());
      }
    }
  }, [addGroup, addGroupErrors, history, welcomeParam]);

  const foundResults = data?.checkADGroupExists ?? [];

  const columnsData = [
    {
      Header: "AD Group Name",
      id: "name",
      accessor: (d) => d?.displayName,
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  onClick={() => addGroupToPlatform(original)}
                  title="Add Group To Platform"
                  disabled={addGroupLoading}
                >
                  Enable
                </Button>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Formik
        initialValues={{
          displayName: "",
          org: "",
        }}
        validate={(values) => {
          let errors = {};
          if (!values.displayName) {
            errors.displayName = "required.";
          }
          return errors;
        }}
        onSubmit={(values) => {
          const variables = {
            groupIdorName: values?.displayName,
            organizationId: values?.org?.value,
          };

          search({ query: checkADGroupExists, variables });
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <h3>Search AD Groups</h3>

            <OrgSelect key={1} setFieldValue={props.setFieldValue} />

            <FormControl>
              <StyledField
                type="text"
                name="displayName"
                label={`Group Name`}
                value={props.values.displayName}
                placeholder={`Group Name`}
              />
              {props.errors?.displayName && (
                <FieldError>{props.errors.displayName}</FieldError>
              )}
            </FormControl>

            <FormActions>
              <SmallText>Search for group then enable</SmallText>

              <>
                {setShowAddGroup ? (
                  <Button
                    list="true"
                    type="button"
                    onClick={() => setShowAddGroup((prev) => !prev)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Link to={"/admin/groups"}>
                    <Button list="true" type="button">
                      Cancel
                    </Button>
                  </Link>
                )}

                <Button danger type="submit">
                  {loading ? <Spinner /> : "Search"}
                </Button>
              </>
              {errors && <ErrorMessages errors={errors} />}
            </FormActions>
          </form>
        )}
      </Formik>

      {foundResults?.length ? (
        <div>
          {addGroupLoading && <SplashLoader text="Adding Group" />}
          {addGroupErrors && <ErrorMessages errors={addGroupErrors} />}
          <h3>AD Results</h3>
          <SortTable
            dontReset={true}
            data={foundResults}
            columns={columnsData}
            loading={loading}
          />
        </div>
      ) : data?.checkADGroupExists ? (
        "No Results Found"
      ) : null}
    </>
  );
};

export default OrganizationComponent;
