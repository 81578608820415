import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useApi } from "../../../api/useApi";
import { availableDataSourcesBareScoreHistory } from "../../../api/dataSourceQueries";
import Loading from "./Loading";
import Error from "./Error";
import DataQualityScoreMinWidget from "../../../components/Widgets/DataSourceWidgets/DataQualityScoreMinWidget";
import { Link } from "react-router-dom";
import StyledLink from "../../../components/StyledLink";
import { Bar, Line } from "react-chartjs-2";
import { format } from "date-fns";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { dataSourceReports } from "../../../common/paths";

const TimelineChart = ({
  data,
  enableOutlierDetection,
  deviationThreshold,
  onlyCompareLatestRuns,
}) => {
  const [outliers, setOutliers] = useState([]);

  useEffect(() => {
    if (enableOutlierDetection && deviationThreshold) {
      const detectedOutliers = [];

      data.forEach((source) => {
        const scoreHistory =
          source.node.latestReport?.scoreHistory?.history ?? [];

        if (scoreHistory.length >= 2) {
          const sortedScoreHistory = [...scoreHistory].sort(
            (a, b) => new Date(a.time) - new Date(b.time)
          );

          if (onlyCompareLatestRuns) {
            // Compare only the latest two entries
            const latestEntry =
              sortedScoreHistory[sortedScoreHistory.length - 1];
            const previousEntry =
              sortedScoreHistory[sortedScoreHistory.length - 2];

            const latestScore = latestEntry.score;
            const previousScore = previousEntry.score;

            if (Math.abs(latestScore - previousScore) >= deviationThreshold) {
              detectedOutliers.push({
                sourceName: source.node.name,
                currentScore: latestScore,
                previousScore: previousScore,
                time: latestEntry.time,
              });
            }
          } else {
            // Compare all consecutive entries
            let previousScore = null;

            sortedScoreHistory.forEach((entry, index) => {
              if (previousScore !== null) {
                const deviation = Math.abs(entry.score - previousScore);

                if (deviation >= deviationThreshold) {
                  detectedOutliers.push({
                    sourceName: source.node.name,
                    sourceId: source?.node?.id,
                    currentScore: entry.score,
                    previousScore: previousScore,
                    time: entry.time,
                  });
                }
              }
              previousScore = entry.score;
            });
          }
        }
      });

      setOutliers(detectedOutliers);
    } else {
      setOutliers([]);
    }
  }, [data, enableOutlierDetection, deviationThreshold, onlyCompareLatestRuns]);

  const generateColor = (index) => {
    const colors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#E57373",
      "#81C784",
      "#64B5F6",
      "#FFD54F",
      "#BA68C8",
      "#4DD0E1",
    ];
    return colors[index % colors.length];
  };

  const parseDataForChart = () => {
    const datasets = data.map((source, index) => {
      const scoreHistory =
        source.node.latestReport?.scoreHistory?.history ?? [];

      return {
        label: source.node.name,
        data: scoreHistory.map((entry) => ({
          x: entry.time,
          y: entry.score,
        })),
        borderColor: generateColor(index),
        backgroundColor: "rgba(0,0,0,0)",
        borderWidth: 2,
      };
    });

    return { datasets };
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: "MMM d, yyyy, h:mm a",
          displayFormats: {
            hour: "MMM d, h:mm a",
            day: "MMM d, yyyy", // Optional, if you want a larger-scale display
          },
        },
        title: {
          display: true,
          text: "Time",
        },
        ticks: {
          maxTicksLimit: 10, // Reduce the number of X-axis ticks
          callback: (value) => {
            const date = new Date(value);
            return date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            });
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { raw } = tooltipItem;
            return `Score: ${raw.y} (${new Date(raw.x).toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })})`;
          },
        },
      },
    },
  };

  return (
    <div>
      {enableOutlierDetection && outliers.length > 0 && (
        <Box
          sx={{
            marginBottom: 2,
            padding: 2,
            backgroundColor: "#FFF3E0",
            border: "1px solid #FFB74D",
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" color="warning.main">
            Warning: Outliers Detected
          </Typography>
          <ul>
            {outliers.map((outlier, index) => (
              <li key={index}>
                <Typography variant="body2">
                  Source:{" "}
                  <StyledLink to={dataSourceReports(outlier?.sourceId)}>
                    {outlier.sourceName}
                  </StyledLink>
                  , Current Score: <strong>{outlier.currentScore}</strong>,
                  Previous Score: <strong>{outlier.previousScore}</strong>,
                  Time:{" "}
                  <strong>
                    {new Date(outlier.time).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </strong>
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      <Line data={parseDataForChart()} options={options} />
    </div>
  );
};

const DataSourceScoreHistory = ({ data }) => {
  const [{ loading, errors, data: dataSourcesAvail }, fetchSources] = useApi();

  let sourcesData = dataSourcesAvail?.availableDataSources?.edges ?? [];

  useEffect(() => {
    if (data?.sources?.length) {
      fetchSources({
        query: availableDataSourcesBareScoreHistory,
        variables: {
          first: data?.sources?.length ?? 9999,
          where: {
            enabled: { eq: true },
            id: { in: data?.sources },
          },
          tagFilter: { exactMatch: false, tagIds: [] },
        },
      });
    }
  }, [fetchSources, data?.sources]);

  if (errors && data) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Error message="There was an error loading the data." />
      </Box>
    );
  }

  if (!dataSourcesAvail) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>

      <TimelineChart
        data={sourcesData}
        enableOutlierDetection={data?.enableOutlierDetection}
        deviationThreshold={data?.deviationThreshold}
        onlyCompareLatestRuns={data?.onlyCompareLatestRuns}
      />
    </>
  );
};

export default DataSourceScoreHistory;
