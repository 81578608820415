import React, { useEffect } from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { useApi } from "../../../api/useApi";
import { format } from "date-fns";
import { getServicerTransferFeedById } from "../../../api/serviceTransferQueries";
import Loading from "./Loading";
import Error from "./Error";
import DataQualityFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityFailuresCountHover";
import DataQualityOpportunitiesForFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityOpportunitiesForFailuresCountHover";
import DataSourceRowsMeasuredHover from "../../../components/Widgets/DataSourceWidgets/DataSourceRowsMeasuredHover";
import DataQualityScore from "../../../components/Widgets/DataSourceWidgets/DataQualityScore";
import { Link } from "react-router-dom";
import { formatTime } from "../../../common/formats";
import CustomResizeHandle from "./CustomResizeHandle";
import FeedSummary from "../../FeedsPage/FeedSummary";

export function findThresholdScore(grade) {
  let scoreObject;

  const thresholdScores = [
    {
      min: 0,
      max: 39,
      name: "Critical",
      color: "#f87e7e",
    },
    {
      min: 40,
      max: 69,
      name: "Low",
      color: "#E76E3C",
    },
    {
      min: 70,
      max: 94,
      name: "Medium",
      color: "#F6C600",
    },
    {
      min: 95,
      max: 100,
      name: "High",
      color: "#40806A",
    },
  ];

  if (grade <= 0) {
    scoreObject = thresholdScores[0];
  }
  thresholdScores.forEach((s, i) => {
    if (grade >= s.min && grade <= s.max) {
      scoreObject = thresholdScores[i];
    }
  });
  return scoreObject;
}

const TransfersSummary = ({ data }) => {
  if (!data) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Error message="There was an error loading the data." />
      </Box>
    );
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>

      {data?.transfer && (
        <FeedSummary feedId={data?.transfer} isWidget={true} />
      )}
    </>
  );
};

export default TransfersSummary;
