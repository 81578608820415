import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  Modal,
  Typography,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Autocomplete,
  Container,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApi } from "../../../api/useApi";
import { availableDataSourcesBare } from "../../../api/dataSourceQueries";
import SpinningLoader from "../../../components/Loaders/SpinningLoader";

const DataSourceAPIScoreHistoryConfig = ({
  data,
  onSubmit,
  onDelete,
  isEditing,
  workspace,
}) => {
  const [{ loading, errors, data: dataSourcesAvail }] = useApi(
    availableDataSourcesBare,
    {
      first: 9999,
      where: {
        enabled: { eq: true },
      },
      tagFilter: { exactMatch: false, tagIds: [] },
    }
  );

  const dataSources = dataSourcesAvail?.availableDataSources?.edges ?? [];

  const dataSourcesOptions =
    dataSources
      ?.sort((a, b) => a?.node?.name.localeCompare(b?.node?.name))
      .map((dv) => ({ label: dv?.node?.name, value: dv?.node?.id })) ?? [];

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...data,
      widgetName: data?.widgetName || "",
      sources: data?.sources || [],
      minWidth: data?.minWidth ?? 4,
      maxWidth: data?.maxWidth ?? 6,
      minHeight: data?.minHeight ?? 13,
      maxHeight: data?.maxHeight ?? 13,
      defaultWidth: data?.defaultWidth ?? 4,
      defaultHeight: data?.defaultHeight ?? 13,
      enableOutlierDetection: data?.enableOutlierDetection ?? false,
      onlyCompareLatestRuns: data?.onlyCompareLatestRuns || false,
      deviationThreshold: data?.deviationThreshold || "",
      showQualityScoreLabel: data?.showQualityScoreLabel || false,
      widgetType: data?.widgetType || "",
      sort: data?.sort || "",
      sectionId: data?.sectionId || "",
    },
  });

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        if (watch(key) !== value) {
          setValue(key, value); // Dynamically update each field
        }
      });
    }
  }, [data, setValue, watch]);

  const selectedSources = watch("sources");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    // Fetch available sections for the specific workspace
    const fetchSections = async () => {
      if (workspace?.configuration?.sections) {
        setSections(workspace.configuration.sections);
      }
    };

    fetchSections();
  }, [workspace]);

  const handleClearSection = () => {
    setValue("sectionId", ""); // Clear the sectionId field
  };

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleConfirmDelete = () => {
    handleCloseDeleteModal();
    onDelete();
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box mt={5} display="flex" justifyContent="center">
          <SpinningLoader text={"Loading Configuration..."} />
        </Box>
      </Container>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <Controller
          name="widgetName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Widget Name" fullWidth />
          )}
        />
      </Box>
      <Box mb={2}>
        <FormControl fullWidth>
          <Controller
            name="sources"
            control={control}
            render={({ field }) => {
              const selectedValues = (field.value || [])
                .map((id) =>
                  dataSourcesOptions.find((option) => option?.value === id)
                )
                .filter(Boolean); // Ensure valid values only

              const filteredOptions = dataSourcesOptions.filter(
                (option) =>
                  !selectedValues.find(
                    (selected) => selected.value === option?.value
                  )
              );

              return (
                <Autocomplete
                  multiple
                  options={filteredOptions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.label}
                  value={selectedValues}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((item) => item?.value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Sources"
                      placeholder="Type to search..."
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option?.label}
                    </li>
                  )}
                />
              );
            }}
          />
        </FormControl>
      </Box>

      <Box mb={2}>
        <Controller
          name="enableOutlierDetection"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Enable Outlier Detection"
            />
          )}
        />
      </Box>
      {watch("enableOutlierDetection") && (
        <>
          <Box mb={2}>
            <Controller
              name="onlyCompareLatestRuns"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Only Compare Latest Run to Last Run"
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="deviationThreshold"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Outlier Threshold"
                  type="number"
                  fullWidth
                  helperText="Enter the score Outlier threshold for outlier detection"
                />
              )}
            />
          </Box>
        </>
      )}

      {sections.length > 0 && (
        <Box mb={2}>
          <Controller
            name="sectionId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} label="Select Section" select fullWidth>
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Button
            variant="contained"
            onClick={handleClearSection}
            sx={{
              backgroundColor: "rgb(18, 17, 53)",
              "&:hover": {
                backgroundColor: "rgba(18, 17, 53, 0.8)",
              },
            }}
          >
            Clear Section
          </Button>
        </Box>
      )}

      <Button
        variant="contained"
        type="submit"
        sx={{
          backgroundColor: "rgb(18, 17, 53)",
          "&:hover": {
            backgroundColor: "rgba(18, 17, 53, 0.8)",
          },
          marginRight: "1rem",
        }}
      >
        {data && isEditing ? "Save Widget" : "Update Preview"}
      </Button>

      {onDelete && (
        <>
          <Button
            variant="contained"
            type="button"
            onClick={handleOpenDeleteModal}
          >
            Remove Widget
          </Button>

          <Modal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              width={400}
              bgcolor="background.paper"
              boxShadow={24}
              p={4}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to delete this widget?
              </Typography>
              <Box mt={4} display="flex" justifyContent="space-between">
                <Button
                  onClick={handleConfirmDelete}
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor: "rgb(18, 17, 53)",
                    "&:hover": {
                      backgroundColor: "rgba(18, 17, 53, 0.8)",
                    },
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={handleCloseDeleteModal} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </form>
  );
};

export default DataSourceAPIScoreHistoryConfig;
