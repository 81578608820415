import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
  MdPageview,
  MdRemoveCircle,
  MdError,
  MdLibraryBooks,
} from "react-icons/md";
import {
  setRuleInstanceEnabledFlag,
  setRuleInstancePriority,
  setRuleInstanceApprovalState,
} from "../../api/ruleMutations";
import { useApi } from "../../api/useApi";
import { StyledSelect } from "../../components/Form/FormControls";
import PagedTable from "../../components/Table/PagedTable";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Spinner from "../../components/Loaders/Spinner";
import { ActionsIcon } from "../../components/Table/elements";
import SplashLoader from "../../components/Loaders/SplashLoader";
import {
  dataSourceManagePoliciesStandardVersion,
  dataSourceFilterVersion,
  editDataQualityRules,
} from "../../common/paths";
import { dataSourceStandardsList } from "../../api/dataSourceQueries";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import columnSort from "../../components/Table/helpers/columnSort";
import { AuthContext } from "../../contexts/AuthContext";
import { approvalStates } from "../../common/formOptions";
import { canUseBCA } from "../../common/helpers/auth";
import ErrorMessages from "../../components/Notifications/ErrorMessages";

const WarningIcon = styled.span`
  color: orange;
`;
const PublishedIcon = styled.span`
  color: green;
`;

const ErrorIcon = styled.span`
  color: red;
`;

const priorityOptions = [
  { label: "HIGH", value: "HIGH" },
  { label: "LOW", value: "LOW" },
  { label: "NORMAL", value: "NORMAL" },
  { label: "INFORMATIONAL", value: "INFORMATIONAL" },
];

const UpdatingLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const AppliedRules = ({ getUpdatedSource, sourceId, mappingType }) => {
  //Available rule instances paged Via Graph
  const [{ errors, loading, data }, getPagedInstances] = useApi();
  const { user } = useContext(AuthContext);

  //currentPage
  const [currentView, setCurrentView] = useState(null);

  const availRules = data?.dataSource?.ruleInstances?.edges ?? [];
  const totalCount = data?.dataSource?.ruleInstances?.totalCount;

  const pageInfo = data?.dataSource?.ruleInstances?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);
      setCurrentView(cursor);
      getPagedInstances({
        query: dataSourceStandardsList,
        variables: {
          first: pageSize,
          after: cursor,
          id: Number(sourceId),
          where: { enabledState: { in: ["DRAFT", "PUBLISHED"] } },
          ...(sortedObject
            ? {
                order: {
                  ...sortedObject,
                },
              }
            : {
                order: {
                  title: "ASC",
                },
              }),
        },
      });
    },
    [getPagedInstances, sourceId, setCurrentView]
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState(null);

  const [
    { loading: removeRuleDataLoading, data: removeRuleData },
    removeRuleApi,
  ] = useApi();

  //callback for removing a row
  const removeRuleStandard = useCallback(
    (ruleInstanceId) => {
      const variables = {
        ruleInstanceId: ruleInstanceId,
        enableState: "DISABLED",
      };
      removeRuleApi({ query: setRuleInstanceEnabledFlag, variables });
    },
    [removeRuleApi]
  );

  //handle remove rule update
  useEffect(() => {
    if (removeRuleData) {
      getUpdatedSource();
      setRuleToDelete(null);
      setShowConfirm(false);
    }
  }, [removeRuleData, getUpdatedSource]);

  useEffect(() => {
    if (ruleToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [ruleToDelete]);

  const [{ loading: priorityLoading, data: priorityUpdate }, doUpdatePriority] =
    useApi();

  const [
    { loading: approvalStateLoading, data: approvalStateUpdate },
    doUpdateApprovalState,
  ] = useApi();

  const updatePriority = React.useCallback(
    (updatePriorityEvent, instanceId) => {
      const updateInstancePriority = {
        instanceId: instanceId,
        priority: updatePriorityEvent.value,
      };

      const variables = {
        ...updateInstancePriority,
      };

      doUpdatePriority({ query: setRuleInstancePriority, variables });
    },
    [doUpdatePriority]
  );

  const updateApprovalState = React.useCallback(
    (updateApprovalStateEvent, instanceId) => {
      const updateInstanceApprovalState = {
        instanceId: instanceId,
        approvalState: updateApprovalStateEvent.value,
      };

      const variables = {
        ...updateInstanceApprovalState,
      };

      doUpdateApprovalState({ query: setRuleInstanceApprovalState, variables });
    },
    [doUpdateApprovalState]
  );

  useEffect(() => {
    if (priorityUpdate?.setRuleInstancePriority) {
      toast.success("Policy Updated", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchData({ pageSize: 10, cursor: currentView ?? null });
    }
  }, [priorityUpdate, fetchData, currentView]);

  useEffect(() => {
    if (approvalStateUpdate?.setRuleInstanceApprovalState) {
      toast.success("Policy Approval State Updated", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      fetchData({ pageSize: 10, cursor: currentView ?? null });
    }
  }, [approvalStateUpdate, fetchData, currentView]);

  let columnsData = [
    {
      Header: "Title",
      id: "title",
      accessor: (d) => d.node.title,
    },
    {
      Header: "State",
      id: "enabledState",
      accessor: (d) => d.node.enabledState,
      Cell: ({ row: { original } }) => {
        const stateOfPolicy = original?.node?.enabledState;

        if (stateOfPolicy === "DRAFT") {
          return (
            <>
              <div style={{ fontSize: "1.2rem" }}>
                <ActionsIcon>
                  <WarningIcon>
                    <div title={"A Draft that will not run"}>
                      {stateOfPolicy}
                    </div>
                  </WarningIcon>
                </ActionsIcon>
              </div>
            </>
          );
        } else if (stateOfPolicy === "PUBLISHED") {
          return (
            <>
              <div style={{ fontSize: "1.2rem" }}>
                <ActionsIcon>
                  <PublishedIcon>
                    <div title={"A Production Rule"}>{stateOfPolicy}</div>
                  </PublishedIcon>
                </ActionsIcon>
              </div>
            </>
          );
        } else {
          return (
            <>
              <div style={{ fontSize: "1.2rem" }}>
                <ActionsIcon>
                  <div title={"A Disabled Rule"}>{stateOfPolicy}</div>
                </ActionsIcon>
              </div>
            </>
          );
        }
      },
    },
    {
      Header: "",
      id: "actions",
      maxWidth: 220,
      Cell: ({ row: { original } }) => {
        const isRuleEnabled =
          original?.node?.latestVersion?.standardVersion?.standard?.enabled;

        const isLatest =
          original?.node?.latestVersion?.standardVersionId ===
          original?.node?.latestVersion?.standardVersion?.standard
            ?.latestVersion?.id;

        return (
          <>
            <div style={{ fontSize: "1.2rem" }}>
              {!isRuleEnabled && (
                <ErrorIcon>
                  <MdError
                    title="Policy has been Disabled"
                    style={{ fontSize: "1.5rem" }}
                  />
                </ErrorIcon>
              )}
              <ActionsIcon>
                <Link
                  to={
                    mappingType === "FILTER"
                      ? dataSourceFilterVersion(
                          sourceId,
                          original?.node?.id,
                          original?.node?.latestVersion?.id
                        )
                      : dataSourceManagePoliciesStandardVersion(
                          sourceId,
                          original?.node?.id,
                          original?.node?.latestVersion?.id
                        )
                  }
                >
                  {!isLatest ? (
                    <WarningIcon>
                      <MdPageview
                        title="View Rule Mapping :: Out of Date"
                        style={{ fontSize: "1.5rem" }}
                      />
                    </WarningIcon>
                  ) : (
                    <MdPageview
                      title="View Rule Mapping"
                      style={{ fontSize: "1.5rem" }}
                    />
                  )}
                </Link>
              </ActionsIcon>
              {user && user.role >= 3 && (
                <ActionsIcon>
                  <WarningIcon>
                    <MdRemoveCircle
                      onClick={() => setRuleToDelete(original?.node?.id)}
                      title="Disable Instance"
                      style={{ fontSize: "1.5rem" }}
                    />
                  </WarningIcon>
                </ActionsIcon>
              )}
              <ActionsIcon>
                <Link to={editDataQualityRules(original?.node?.standardId)}>
                  <MdLibraryBooks
                    title="View Policy Template"
                    style={{ fontSize: "1.5rem" }}
                  />
                </Link>
              </ActionsIcon>
            </div>
          </>
        );
      },
    },
  ];

  if (mappingType === "RULE") {
    columnsData = [
      ...columnsData,
      {
        Header: "Impact",
        id: "priority",
        accessor: "priority",
        maxWidth: 220,
        Cell: ({ row: { original } }) => {
          return (
            <>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                menuPortalTarget={document.body}
                name={`${original?.node?.id}-priority`}
                id={`${original?.node?.id}-priority`}
                inputId={`${original?.node?.id}-priority-input`}
                instanceId={`${original?.node?.id}-priority-instance`}
                options={priorityOptions}
                onChange={(e) => updatePriority(e, original?.node?.id)}
                placeholder={original?.node?.priority}
                value={priorityOptions.find(
                  (priority) => priority.value === original?.node?.priority
                )}
              />
            </>
          );
        },
      },
      ...(canUseBCA(user?.email)
        ? [
            {
              Header: "Approval State",
              id: "approvalState",
              accessor: "approvalState",
              maxWidth: 200,
              Cell: ({ row: { original } }) => {
                return (
                  <>
                    <StyledSelect
                      className={`react-select-container`}
                      classNamePrefix={`react-select`}
                      menuPortalTarget={document.body}
                      name={`${original?.node?.id}-approvalState`}
                      id={`${original?.node?.id}-approvalState`}
                      inputId={`${original?.node?.id}-approvalState-input`}
                      instanceId={`${original?.node?.id}-approvalState-instance`}
                      options={approvalStates}
                      onChange={(e) =>
                        updateApprovalState(e, original?.node?.id)
                      }
                      placeholder={original?.node?.approvalState}
                      value={approvalStates.find(
                        (approvalState) =>
                          approvalState.value === original?.node?.approvalState
                      )}
                      minWidth={200}
                    />
                  </>
                );
              },
            },
          ]
        : []),
    ];
  }

  return (
    <>
      {errors ? <ErrorMessages errors={errors} /> : null}
      {showConfirm ? (
        <Modal
          title={`Confirm Rule Removal`}
          hide={() => setRuleToDelete(null)}
        >
          <p>Are you sure you wish to remove this Mapping?</p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removeRuleDataLoading}
              danger
              onClick={() => removeRuleStandard(ruleToDelete)}
            >
              {removeRuleDataLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removeRuleDataLoading}
              onClick={() => {
                setRuleToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}
      {priorityLoading ? (
        <UpdatingLoading>
          <SplashLoader text={"Updating Priority"} />
        </UpdatingLoading>
      ) : null}

      {approvalStateLoading ? (
        <UpdatingLoading>
          <SplashLoader text={"Updating Approval State"} />
        </UpdatingLoading>
      ) : null}

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={availRules}
        columns={columnsData}
        defaultPageSize={50}
      />
      <ToastContainer />
    </>
  );
};

export default AppliedRules;
