import React, { useState, useEffect } from "react";
import {
  BusinessRuleSourceValues,
  BusinessRuleOperations,
  BusinessRuleTargetValues,
  BusinessRuleVariables,
  BusinessRuleVariableOperations,
  ColumnModifiers,
} from "./buildingBlocks";
import { useApi } from "../../../api/useApi";
import { withRouter } from "react-router-dom";
import { businessRuleStandard } from "../../../api/ruleQueries";
import { v1 as uuid } from "uuid";
import { sortByCreatedOn } from "../../../common/helpers/util";
import Container from "./RuleContainer";
import ErrorMessages from "../../Notifications/ErrorMessages";
import SplashLoader from "../../Loaders/SplashLoader";

const RuleContainer = (props) => {
  const { match, isFilter, isEditing, triggerRefresh } = props;

  const standardId = (match && match.params && match.params.ruleId) || null;

  const [initialData, setInitialData] = useState({
    permissions: [],
    standard: [],
  });

  const [{ loading, errors, data: apiData }, ruleStandardFetch] = useApi();

  useEffect(() => {
    if (standardId) {
      const variables = {
        standardId: Number(standardId),
      };
      ruleStandardFetch({ query: businessRuleStandard, variables });
    }
  }, [standardId, ruleStandardFetch]);

  useEffect(() => {
    if (apiData) {
      let newCards = [];
      const ruleVersions = apiData.businessRuleStandardById.versions;

      const isDuplicateColumnRule = apiData.businessRuleStandardById.id === -2;
      const isDuplicateRecordRule = apiData.businessRuleStandardById.id === -3;

      const sortedVersions = sortByCreatedOn(ruleVersions);

      const currentRuleVersions = sortedVersions.length
        ? sortedVersions[0]
        : [];
      const sorted = currentRuleVersions.fragments.sort(function (a, b) {
        return a.executionOrder - b.executionOrder;
      });

      sorted.forEach((frag, i) => {
        const fragType = frag.typeInformation[0].typeValue;
        if (fragType === 1) {
          // Condition
          const conditionNames = ["If", "Else If", "Else", "End If", "Then"];
          const fragment = {
            typeInformation: frag.typeInformation,
            id: uuid(),
            name: conditionNames[frag.typeInformation[1].typeValue - 1],
            def: "conditionOperators",
          };

          newCards.push(fragment);
        } else if (fragType === 2) {
          // Condition Operator

          const conditionOperatorNames = ["And", "Or", "(", ")"];
          const fragment = {
            typeInformation: frag.typeInformation,
            id: uuid(),
            name: conditionOperatorNames[frag.typeInformation[1].typeValue - 1],
            def: "conditionOperators",
          };
          newCards.push(fragment);
        } else if (fragType === 4) {
          // Source

          //Map Source Dropdown to value for react-select
          const source = { typeInformation: frag.typeInformation };

          source.typeInformation[1].typeValue = BusinessRuleSourceValues.find(
            (sv) => sv.value === source.typeInformation[1].typeValue
          );

          if (source.typeInformation[2]) {
            source.typeInformation[2].typeValue = ColumnModifiers.find(
              (sv) => sv.value === source.typeInformation[2].typeValue
            );
          }

          const operation = { typeInformation: sorted[i + 1].typeInformation };
          operation.typeInformation[1].typeValue = BusinessRuleOperations.find(
            (sv) => sv.value === operation.typeInformation[1].typeValue
          );

          //Handle the case in which operation is set to IsEmpty, No Target should be sent or loaded
          let target;
          if (!isDuplicateColumnRule && !isDuplicateRecordRule) {
            const businessRule = operation.typeInformation[1].typeValue.value;

            const isByAmountOrisByPercentage =
              operation.typeInformation[2]?.typeValue === 4 ||
              operation.typeInformation[2]?.typeValue === 5 ||
              operation.typeInformation[2]?.typeValue === 7 ||
              operation.typeInformation[2]?.typeValue === 8;

            if (
              (businessRule === 6 && !isByAmountOrisByPercentage) ||
              (businessRule === 14 && !isByAmountOrisByPercentage) ||
              (businessRule === 15 && !isByAmountOrisByPercentage)
            ) {
              target = null;
            } else {
              target = { typeInformation: sorted[i + 2].typeInformation };
              target.typeInformation[1].typeValue =
                BusinessRuleTargetValues.find(
                  (sv) => sv.value === target.typeInformation[1].typeValue
                );

              if (
                target.typeInformation[1] &&
                target.typeInformation[1].typeValue &&
                target.typeInformation[1].typeValue.value &&
                target.typeInformation[1].typeValue.value === 3
              ) {
                target.typeInformation[1].fragmentValue =
                  BusinessRuleVariables.find(
                    (sv) =>
                      sv.value ===
                      Number(target.typeInformation[1].fragmentValue)
                  );
              }

              if (target.typeInformation[2]) {
                target.typeInformation[2].typeValue =
                  BusinessRuleVariableOperations.find(
                    (sv) => sv.value === target.typeInformation[2].typeValue
                  );
              }

              if (target.typeInformation[3]) {
                target.typeInformation[3].typeValue = ColumnModifiers.find(
                  (sv) => sv.value === target.typeInformation[3].typeValue
                );
              }
            }
          }

          const fragment = {
            id: uuid(),
            name: "Fragment",
            def: "fragment",
            source: source,
            operation: operation,
            target: target,
          };
          newCards.push(fragment);
        }
      });

      setInitialData({
        permissions: apiData.businessRuleStandardById.permissions.map(
          (perm) => {
            return {
              accessingWorkGroupId: perm.accessingWorkGroupId,
              permissionCode: perm.permissionCode,
            };
          }
        ),
        tagInstances:
          apiData.businessRuleStandardById?.tagInstances?.map((ti) => {
            const createTagOption = {
              label: ti?.tag?.name,
              value: ti?.tagId,
              ...ti,
            };
            return createTagOption;
          }) ?? null,
        standard: newCards,
        name: apiData.businessRuleStandardById.name,
        description: apiData.businessRuleStandardById.description,
      });
    }
  }, [apiData, setInitialData]);

  if (errors) return <ErrorMessages errors={errors} />;

  if ((standardId && !initialData.standard.length) || loading)
    return <SplashLoader text="Loading Data" />;

  return (
    <Container
      standardId={standardId ? standardId : null}
      initialData={initialData}
      isFilter={isFilter}
      isEditing={isEditing}
      sourceInstances={props.sourceInstances}
      triggerRefresh={triggerRefresh}
      {...props}
    />
  );
};

const RuleContainerWithRouter = withRouter(RuleContainer);
export default RuleContainerWithRouter;
