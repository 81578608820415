import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();

// List of URLs to omit telemetry for
// Check if the current hostname matches any excluded URLs
const url = new URL(window.location.href);
const subDomain = url.hostname.split(".")[0].toLowerCase();

const isExcluded = [
  "test",
  "test2",
  "test3",
  "test4",
  "performance",
  "localhost",
].includes(subDomain);
// Only initialize Application Insights if not excluded
let appInsights = null;

if (!isExcluded) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString:
        "InstrumentationKey=c18d5577-86e6-41a0-b290-8a155e7e9d00;IngestionEndpoint=https://westus2-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/;ApplicationId=669f9408-9856-4692-b8d0-83afb6c8bdf1",
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
        [clickPluginInstance.identifier]: {
          autoCapture: true,
          onBeforeEventSend: (event) => {
            // Ensure event properties are correctly checked
            const id = event.properties?.id || null;
            const contentName =
              event.properties?.["data-ai-content-name"] || null;

            // Optionally, set a custom name for meaningful events
            if (contentName) {
              event.name = `ClickEvent - ${contentName}`;
            }

            return true; // Allow the event to be sent
          },
        },
      },
      disableAjaxTracking: true,
      disableFetchTracking: true,
    },
  });

  appInsights.config.enableDebug = false;

  appInsights.config.extensionConfig[clickPluginInstance.identifier] = {
    autoCapture: true, // Enable click auto-capture
  };

  appInsights.loadAppInsights();
  appInsights.config.enableAutoRouteTracking = false;

  appInsights.addTelemetryInitializer((telemetryItem) => {
    // Add environment info
    telemetryItem.data["environment"] = window.location.hostname;
  });
}

/**
 * Set the authenticated user context for Application Insights.
 * @param {string} email - The user's email address.
 * @param {string} [userId] - Optional unique user ID (e.g., GUID).
 */
export const setUserContext = (email, userId = null) => {
  if (appInsights) {
    if (email) {
      appInsights.setAuthenticatedUserContext(userId || email, email);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  } else {
    console.debug("AppInsights not initialized. Skipping setUserContext.");
  }
};

/**
 * Track custom events in Application Insights.
 * @param {string} action - Action type for the event.
 * @param {string} entityType - Entity type for the event.
 * @param {string} [entityId=""] - Entity ID for the event.
 * @param {string} [details=""] - Additional details for the event.
 * @param {object} [additionalProperties={}] - Extra custom properties.
 */
export const trackCustomEvent = (
  action,
  entityType,
  entityId = "",
  details = "",
  additionalProperties = {}
) => {
  if (appInsights) {
    try {
      appInsights.trackEvent({
        name: "CustomActionEvent",
        properties: {
          action,
          entityType,
          entityId,
          details,
          ...additionalProperties,
        },
      });
    } catch (error) {
      console.error("Error tracking custom event:", error);
    }
  } else {
    console.debug("AppInsights not initialized. Skipping event tracking.");
  }
};

export { appInsights, reactPlugin, history };
