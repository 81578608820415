import React, { useEffect } from "react";
import { useApi } from "../../api/useApi";
import { getOrganization } from "../../api/organizationQueries";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { MdGroupWork } from "react-icons/md";
import { Formik } from "formik";
import {
  FormControl,
  FormActions,
  FieldError,
  Label,
  StyledField,
  StyledSelect,
} from "../../components/Form/FormControls";
import Spinner from "../../components/Loaders/Spinner";
import SplashLoader from "../../components/Loaders/SplashLoader";
import { organizations } from "../../common/paths";
import { updateOrganization } from "../../api/organizationMutations";
import ErrorMessages from "../../components/Notifications/ErrorMessages";

const orgRoles = [
  { label: "THIRD PARTY", value: "THIRD_PARTY" },
  { label: "CORE", value: "CORE" },
  { label: "ADMINISTRATOR", value: "ADMINISTRATOR" },
];
const Organization = () => {
  let params = useParams();
  let history = useHistory();
  const orgId = params?.orgId;

  const [{ loading, errors, data }] = useApi(getOrganization, { id: orgId });

  const [
    { loading: updateLoading, errors: updateErrors, data: updateData },
    updateOrg,
  ] = useApi();

  const organization = data?.organizationQueries?.getOrganization ?? null;

  useEffect(() => {
    if (updateData && !updateErrors) {
      history.push(organizations());
    }
  }, [updateData, history, updateErrors]);

  if (loading) return <SplashLoader text="Loading Organization" />;

  if (errors) return <ErrorMessages errors={errors} />;

  return (
    <>
      <h3>
        <MdGroupWork />
        {organization?.displayName}
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={{
          displayName: organization ? organization.displayName : "",
          role: organization
            ? orgRoles.find((role) => role.value === organization?.role)
            : { label: "THIRD PARTY", value: "THIRD_PARTY" },
          invitationRecipient: "",
          tenantId: "",
        }}
        validate={(values) => {
          let errors = {};
          if (!values.displayName) {
            errors.displayName = "required.";
          }
          if (!values.role) {
            errors.role = "required.";
          }
          return errors;
        }}
        onSubmit={(values) => {
          const variables = {
            id: organization?.id,
            displayName: values.displayName,
            role: values.role.value,
          };
          updateOrg({ query: updateOrganization, variables });
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <h3>Update Organization</h3>
            <FormControl>
              <StyledField
                type="text"
                name="displayName"
                label={`Organization Name`}
                value={props.values.displayName}
                placeholder={`Name`}
              />
              {props.errors?.displayName && (
                <FieldError>{props.errors.displayName}</FieldError>
              )}
            </FormControl>

            <FormControl>
              <Label>Organization Role</Label>

              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                menuPortalTarget={document.body}
                name={`${props.values.role}-role`}
                id={`${props.values.role}-role`}
                inputId={`${props.values.role}-role-input`}
                instanceId={`${props.values.role}-role-instance`}
                options={orgRoles}
                onChange={(e) => props.setFieldValue(`role`, e)}
                placeholder={props.values.role}
                value={orgRoles.find(
                  (role) => props.values.role.value === role.value
                )}
              />
              {props.errors?.role && (
                <FieldError>{props.errors.role}</FieldError>
              )}
            </FormControl>

            <FormActions>
              <>
                <Link to={organizations}>
                  <Button danger list="true" type="button">
                    Cancel
                  </Button>
                </Link>
                <Button type="submit">
                  {updateLoading ? <Spinner /> : "Update"}
                </Button>
              </>
              {updateErrors && <ErrorMessages errors={updateErrors} />}
            </FormActions>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Organization;
