import React, { useState, useEffect, useCallback } from "react";
import { useApi } from "../../../api/useApi";
import Spinner from "../../Loaders/Spinner";
import { simpleStandardsList } from "../../../api/ruleQueries";
import { setRuleStandardEnabledFlag } from "../../../api/ruleMutations";
import ErrorMessages from "../../Notifications/ErrorMessages";
import * as paths from "../../../common/paths";
import { Link } from "react-router-dom";
import Button from "../../Button";
import { filterDisabled } from "../../../common/helpers/util";
import Modal from "../../Modal";
import PagedTable from "../../Table/PagedTable";
import TableButton from "../../Button/TableButton";
import SearchPolicies from "./SeachPolicies";
import FilterByTags from "../../Tags/FilterByTags";

const DataSourceFiltersListWidget = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [ruleToDelete, setruleToDelete] = useState(null);

  //Filter Tags
  const [tagFilter, setTagFilter] = useState([]);
  const [exactMatchTagFilter, setExactMatchTagFilter] = useState(false);
  const [manuallySelected, setManuallySelected] = useState(null);

  //Init Data Fetch
  const [
    { availableStandardsLoading, availableStandardsErrors, data: standards },
    doFetch,
  ] = useApi();

  const totalCount = standards?.availableBusinessRuleStandards?.totalCount;
  const pageInfo = standards?.availableBusinessRuleStandards?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: simpleStandardsList,
        variables: {
          first: pageSize,
          after: cursor,
          tagFilter: {
            tagIds: [...tagFilter?.map((tf) => tf.tagId)],
            exactMatch: exactMatchTagFilter,
          },
          standardType: "FILTER",
          where: {
            enabled: { eq: true },
          },
          order: {
            name: "ASC",
          },
        },
      });
    },
    [doFetch, exactMatchTagFilter, tagFilter]
  );

  useEffect(() => {
    if (ruleToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [ruleToDelete]);

  //remove rules state

  const [
    { loading: removeRuleDataLoading, data: removeRuleData },
    removeRuleApi,
  ] = useApi();

  //handle remove rule update
  useEffect(() => {
    if (removeRuleData) {
      //set filtered rule
      setRules((prevRules) =>
        prevRules.filter(
          (rule) => rule.id !== removeRuleData?.setRuleStandardEnabledFlag?.id
        )
      );
      setruleToDelete(null);
    }
  }, [removeRuleData]);

  //define initial rule state
  const [rules, setRules] = useState([]);

  //callback for removing a row
  const removeRuleStandard = useCallback(
    (templateId) => {
      const variables = { id: templateId, enabled: false };
      removeRuleApi({ query: setRuleStandardEnabledFlag, variables });
    },
    [removeRuleApi]
  );

  //set rules into local state so we can mutate on remove
  useEffect(() => {
    if (standards) {
      const availableStandards =
        standards?.availableBusinessRuleStandards?.edges ?? [];
      setRules(filterDisabled(availableStandards));
    }
  }, [standards]);

  if (availableStandardsLoading) return <Spinner />;
  if (availableStandardsErrors)
    return <ErrorMessages errors={availableStandardsErrors} />;

  const columnsData = [
    {
      Header: "Title",
      id: "title",
      accessor: (d) => d?.node?.name,
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  onClick={() => setManuallySelected([ti])}
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                    cursor: "pointer",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 220,
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <Link to={paths.dataSourceFilterById(original?.node?.id)}>
              <TableButton list="true" type="button">
                Manage
              </TableButton>
            </Link>

            <TableButton
              list="true"
              danger
              onClick={() => setruleToDelete(original?.node?.id)}
              type="button"
            >
              Remove
            </TableButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Filter Removal`}
          hide={() => setruleToDelete(null)}
        >
          <p>Are you sure you wish to remove this Filter?</p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removeRuleDataLoading}
              danger
              onClick={() => removeRuleStandard(ruleToDelete)}
            >
              {removeRuleDataLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removeRuleDataLoading}
              onClick={() => {
                setruleToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, marginRight: "1rem" }}>
          <SearchPolicies ruleType={2} />
        </div>
        <div style={{ flex: 1, marginTop: "1rem", marginBottom: "1rem" }}>
          <FilterByTags
            currentTags={manuallySelected}
            updateTags={setTagFilter}
            tagType="POLICY"
            exactMatch={setExactMatchTagFilter}
          />
        </div>
      </div>

      <PagedTable
        fetchData={fetchData}
        loading={availableStandardsLoading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={rules}
        columns={columnsData}
      />
    </>
  );
};

export default DataSourceFiltersListWidget;
