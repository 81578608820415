import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import STRINGFORMAT from "./StringFormat.md";
import DATETIMEFORMAT from "./DateTimeFormat.md";
import DECIMALFORMAT from "./DecimalFormat.md";
import COALESCE from "./CoalesceFormat.md";
import COALESCEDICTIONARY from "./COALESCEDICTIONARY.md";
import DICTIONARY from "./Dictionary.md";
import DICTIONARYPASSTHROUGH from "./DictionaryPassThrough.md";
import STRINGREPLACE from "./StringReplace.md";
import STRINGSPLIT from "./StringSplit.md";
import FULLNAMEPARSE from "./FullNameParse.md";
import PHONENUMBERPARSE from "./PhoneNumberParse.md";
import ADDRESSPARSE from "./AddressParse.md";
import MONTHADJUSTMENT from "./MonthAdjustment.md";
import CALCULATOR from "./Calculator.md";
import NCALC from "./NCalc.md";
import { MdClear } from "react-icons/md";
import SourceLocation from "../../../common/markdown/Ingress.md";
import ExportPath from "../../../common/markdown/Egress.md";
import WildcardEgress from "../../../common/markdown/WildcardEgress.md";
import WildcardIngress from "../../../common/markdown/WildcardIngress.md";
import ValueSeparator from "../../../common/markdown/ValueSeparator.md";
import QuotesIngress from "../../../common/markdown/QuotesIngress.md";
import QuotesIgnore from "../../../common/markdown/QuotesIgnore.md";
import InternalToIngress from "../../../common/markdown/InternalToIngress.md";

import Modifiers from "./Modifiers.md";
const Fetcher = ({ MdFile, close, hideClose }) => {
  const [content, setContent] = useState("");

  let findFile = null;
  switch (MdFile) {
    default: {
      findFile = null;
      break;
    }
    case "STRING_FORMAT": {
      findFile = STRINGFORMAT;
      break;
    }
    case "DATE_TIME_FORMAT": {
      findFile = DATETIMEFORMAT;
      break;
    }
    case "DECIMAL_FORMAT": {
      findFile = DECIMALFORMAT;
      break;
    }
    case "COALESCE": {
      findFile = COALESCE;
      break;
    }
    case "COALESCE_DICTIONARY": {
      findFile = COALESCEDICTIONARY;
      break;
    }
    case "DICTIONARY": {
      findFile = DICTIONARY;
      break;
    }
    case "DICTIONARY_PASS_THROUGH": {
      findFile = DICTIONARYPASSTHROUGH;
      break;
    }
    case "STRING_REPLACE": {
      findFile = STRINGREPLACE;
      break;
    }
    case "STRING_SPLIT": {
      findFile = STRINGSPLIT;
      break;
    }
    case "FULL_NAME_PARSE": {
      findFile = FULLNAMEPARSE;
      break;
    }
    case "PHONE_NUMBER_PARSE": {
      findFile = PHONENUMBERPARSE;
      break;
    }
    case "ADDRESS_PARSE": {
      findFile = ADDRESSPARSE;
      break;
    }
    case "MONTH_ADJUSTMENT": {
      findFile = MONTHADJUSTMENT;
      break;
    }
    case "CALCULATOR": {
      findFile = CALCULATOR;
      break;
    }
    case "N_CALC": {
      findFile = NCALC;
      break;
    }
    case "SourceLocation": {
      findFile = SourceLocation;
      break;
    }
    case "ExportPath": {
      findFile = ExportPath;
      break;
    }
    case "WildcardEgress": {
      findFile = WildcardEgress;
      break;
    }
    case "WildcardIngress": {
      findFile = WildcardIngress;
      break;
    }
    case "ValueSeparator": {
      findFile = ValueSeparator;
      break;
    }
    case "QuotesIngress": {
      findFile = QuotesIngress;
      break;
    }
    case "QuotesIgnore": {
      findFile = QuotesIgnore;
      break;
    }
    case "InternalToIngress": {
      findFile = InternalToIngress;
      break;
    }
    case "MODIFIERS": {
      findFile = Modifiers;
      break;
    }
  }

  useEffect(() => {
    if (findFile) {
      fetch(findFile)
        .then((res) => res.text())
        .then((text) => setContent(text));
    }
  }, [findFile]);

  if (!findFile) {
    return null;
  } else {
    if (!content) return null;
    return (
      <div className="markdown" style={{ position: "relative" }}>
        {!hideClose && (
          <div
            style={{
              position: "absolute",
              top: "-1rem",
              right: "-.5rem",
              cursor: "pointer",
              fontSize: "2rem",
            }}
            onClick={() => close(false)}
            title="Close Help"
          >
            <MdClear />
          </div>
        )}
        <ReactMarkdown children={content} />
      </div>
    );
  }
};

export default Fetcher;
