import { useContext } from "react";
import { SocketContext } from "../contexts/useSubscriptions";

const UseDataSourceRowByPK = () => {
  const { dataSourceRowByPK, setDataSourceRowByPK } = useContext(SocketContext);

  return { dataSourceRowByPK, setDataSourceRowByPK };
};

export default UseDataSourceRowByPK;
