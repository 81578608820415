import {
  auditLogEntriesQuery,
  auditLogEntriesQueryUserActions,
} from "../../api/auditQueries";
import { format } from "date-fns";
import React, { useState } from "react";
import { useApi } from "../../api/useApi";
import PagedExpandTable from "../../components/Table/PagedExpandTable";
import { StyledSelect, Label } from "../../components/Form/FormControls";
import ExpandLink from "../../components/Button/ExpandLink";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import StyledLink from "../../components/StyledLink";
import { useParams, Link, NavLink, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Button from "../Button";
import columnSort from "../../components/Table/helpers/columnSort";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import Modal from "../../components/Modal";
import ExportAuditLog from "./ExportAuditLog";
import PermissionsLog from "./PermissionsLog";
import { admin, adminAuditPermissions } from "../../common/paths";

const ViewByOptions = [
  { label: "All", value: "All" },
  { label: "User Actions", value: "User Actions" },
  { label: "User Logins", value: "User Logins" },
  // -- action Types --//
  { label: "DataSource", value: "DATA_SOURCE" },
  { label: "Connection", value: "CONNECTION" },
  { label: "Policy", value: "POLICY" },
  { label: "Workflow", value: "WORKFLOW" },
  { label: "Transformation", value: "SERVICER_TRANSFER_FEED" },
  { label: "Alert", value: "ALERT" },
  { label: "Tag", value: "TAG" },
  { label: "Issue", value: "ISSUE" },
  { label: "System", value: "SYSTEM" },
  { label: "Platform User", value: "PLATFORM_USER" },
];

const ColumnPillContainers = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: solid 1px #ccc;
  text-align: center;
  margin-right: 1rem;
`;

function handleUrl(actionType, eventObjectId) {
  const urlstring = `/admin/audit/${actionType}/${eventObjectId}`;
  switch (actionType) {
    case "DATA_SOURCE":
    case "POLICY":
    case "WORKFLOW":
    case "SERVICER_TRANSFER_FEED":
    case "ALERT":
    case "TAG":
    case "ISSUE":
    case "SYSTEM":
    case "PLATFORM_USER":
    case "CONNECTION": {
      return urlstring;
    }
    default: {
      return null;
    }
  }
}

const AuditDetails = ({ props }) => {
  const originalRow = props?.row?.original?.node?.additionalData;
  const parsed = JSON.parse(JSON.parse(originalRow));

  return (
    <div style={{ background: "#efefee78", padding: "1rem" }}>
      <h4>Additional Platform Details</h4>
      {parsed && (
        <div>
          <pre style={{ margin: "1em 0" }}>
            {JSON.stringify(parsed, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

const Audit = () => {
  let params = useParams();
  const [showExport, setShowExport] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    label: "All",
    value: "All",
  });

  const columnsData = [
    {
      Header: "Action",
      id: "action",
      width: 300,
      accessor: (d) => d?.node?.action,
      Cell: ({ row }) => {
        return (
          <div {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <ExpandLink title={row?.original?.node?.action}>
                <div>
                  <MdExpandLess />
                </div>
                <div style={{ fontSize: "0.875rem" }}>
                  {row?.original?.node?.action}
                </div>
              </ExpandLink>
            ) : (
              <ExpandLink title={row?.original?.node?.action}>
                <div>
                  <MdExpandMore />
                </div>
                <div style={{ fontSize: "0.875rem" }}>
                  {row?.original?.node?.action}
                </div>
              </ExpandLink>
            )}
          </div>
        );
      },
    },
    {
      Header: "Entity Type",
      id: "remoteObjectType",
      accessor: (d) => d?.node?.remoteObjectType,
    },
    {
      Header: "Entity Name",
      id: "remoteObjectName",
      accessor: (d) => d?.node?.remoteObjectName,
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.node?.remoteObjectName && (
              <StyledLink
                style={{ fontSize: "0.875rem" }}
                to={handleUrl(
                  row?.original?.node?.remoteObjectType,
                  row?.original?.node?.remoteObjectId
                )}
              >
                {row?.original?.node?.remoteObjectName}
              </StyledLink>
            )}
          </div>
        );
      },
    },
    {
      Header: "Email",
      id: "performingUser.emailAddress",
      accessor: (d) =>
        d?.node?.performingUser ? d?.node?.performingUser.emailAddress : null,
    },
    {
      Header: "Date",
      id: "timestamp",
      accessor: (d) =>
        d?.node?.timestamp
          ? format(new Date(d?.node?.timestamp), "MM-dd-yyyy HH:mm:ss")
          : null,
    },
  ];

  //Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const auditLogEntries = apiData?.auditLogEntries?.edges ?? [];
  const totalCount = apiData?.auditLogEntries?.totalCount;
  const pageInfo = apiData?.auditLogEntries?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor, sortBy }) => {
      const sortedObject = columnSort(sortBy);

      if (selectedFilter?.value === "User Actions") {
        doFetch({
          query: auditLogEntriesQueryUserActions,
          variables: {
            first: pageSize,
            after: cursor,
            action: null,
            remoteType: params?.actionType,
            where: {
              performingUserId: { neq: -1 },
              ...(params?.actionId && {
                remoteObjectId: { eq: Number(params?.actionId) },
              }),
            },
            ...(sortedObject
              ? {
                  order: {
                    ...sortedObject,
                  },
                }
              : {
                  order: {
                    timestamp: "DESC",
                  },
                }),
          },
        });
      } else if (selectedFilter?.value === "User Logins") {
        doFetch({
          query: auditLogEntriesQueryUserActions,
          variables: {
            first: pageSize,
            after: cursor,
            action: "USER_LOGIN_ATTEMPT",
            remoteType: params?.actionType,
            where: {
              ...(params?.actionId && {
                remoteObjectId: { eq: Number(params?.actionId) },
              }),
            },
            ...(sortedObject
              ? {
                  order: {
                    ...sortedObject,
                  },
                }
              : {
                  order: {
                    timestamp: "DESC",
                  },
                }),
          },
        });
      } else if (selectedFilter?.value !== "All" && selectedFilter?.value) {
        doFetch({
          query: auditLogEntriesQueryUserActions,
          variables: {
            first: pageSize,
            after: cursor,
            action: null,
            remoteType: selectedFilter?.value,
            ...(sortedObject
              ? {
                  order: {
                    ...sortedObject,
                  },
                }
              : {
                  order: {
                    timestamp: "DESC",
                  },
                }),
          },
        });
      } else {
        doFetch({
          query: auditLogEntriesQuery,
          variables: {
            first: pageSize,
            after: cursor,
            action: null,
            remoteType: params?.actionType,
            where: {
              ...(params?.actionId && {
                remoteObjectId: { eq: Number(params?.actionId) },
              }),
            },
            ...(sortedObject
              ? {
                  order: {
                    ...sortedObject,
                  },
                }
              : {
                  order: {
                    timestamp: "DESC",
                  },
                }),
          },
        });
      }
    },
    [doFetch, selectedFilter, params?.actionId, params?.actionType]
  );

  return (
    <>
      {showExport ? (
        <Modal title={`Export Audit Log`} hide={() => setShowExport(false)}>
          <ExportAuditLog cancel={() => setShowExport(false)} />
        </Modal>
      ) : null}

      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "180px",
            marginRight: "1rem",
            display: "inline-block",
          }}
        >
          {params?.actionId && params?.actionType ? (
            <div>
              <Label>View by</Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ColumnPillContainers>
                  <div>{params?.actionType}</div>
                </ColumnPillContainers>
                <ColumnPillContainers>
                  <div>{params?.actionId}</div>
                </ColumnPillContainers>

                <Link to={admin}>
                  <Button danger list="true" type="button" title="Clear Filter">
                    X
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              <Label>View by</Label>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`sortTable`}
                id={`viewBy`}
                inputId={`viewBySelect-input`}
                instanceId={`viewBySelect-instance`}
                options={ViewByOptions}
                menuPortalTarget={document.body}
                value={selectedFilter}
                menuPlacement="auto"
                onChange={(e) => setSelectedFilter(e)}
              />
            </>
          )}
        </div>

        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            title={`Export`}
            list={"true"}
            onClick={() => setShowExport(true)}
          >
            {`Export`}
          </Button>
        </div>
      </div>

      <PagedExpandTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={auditLogEntries}
        columns={columnsData}
        defaultPageSize={50}
        SubRowComponent={(props) => <AuditDetails props={props} />}
      />
      {errors ? <ErrorMessages errors={errors} /> : null}
    </>
  );
};

const TabLink = styled(NavLink)`
  font-size: 0.9rem;
  padding: 0.7rem;
  border-left: 4px solid transparent;
  text-decoration: none;
  opacity: 0.5;
  display: inline-block;
  color: ${(props) => props.theme.onSurface};
  &.active {
    opacity: 1;
    border-left: 4px solid ${(props) => props.theme.onSecondarySurface};
  }
  &:hover {
    opacity: 1;
  }
  text-transform: uppercase;
`;

const ManageContainer = styled.div`
  display: flex;
`;

const ManageContent = styled.div`
  padding: 1rem;
  flex: 1;
  display: block;
  overflow-x: auto;
`;

const AuditWrapper = () => {
  return (
    <ManageContainer>
      <ul style={{ borderRight: "1px solid #efefef" }}>
        <li>
          <TabLink
            to={admin}
            isActive={(match, location) => {
              if (!match) {
                return false;
              }
              return (
                location.pathname === admin ||
                (location.pathname.includes(admin) &&
                  !location.pathname.includes(adminAuditPermissions))
              );
            }}
          >
            Actions
          </TabLink>
        </li>
        <li>
          <TabLink exact to={adminAuditPermissions}>
            Permissions
          </TabLink>
        </li>
      </ul>
      <ManageContent>
        <Switch>
          <Route
            path={`/admin/audit/permissions`}
            component={() => <PermissionsLog />}
          />
          <Route
            path={`/admin/audit/:actionType/:actionId`}
            component={() => <Audit />}
          />

          <Route path={`/admin/audit`} component={() => <Audit />} />
        </Switch>
      </ManageContent>
    </ManageContainer>
  );
};

export default AuditWrapper;
