import React, { useEffect, useState, useCallback } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  FormControl,
  StyledSelect,
  Label,
  StyledInput,
  FormActions,
} from "../../Form/FormControls";
import Button from "../../Button";
import SelectTags from "../../Tags/SelectTags";
import { simpleStandardsList } from "../../../api/ruleQueries";
import {
  availableDataSources,
  availableDataSourcesInstances,
} from "../../../api/dataSourceQueries";
import { useApi } from "../../../api/useApi";
import Modal from "../../Modal";
import SortTable from "../../Table/SortTable";
import TableButton from "../../Button/TableButton";
import {
  MdDelete,
  MdArrowBack,
  MdOutlineWarningAmber,
  MdSelectAll,
} from "react-icons/md";
import { upsertRecordLevelReport } from "../../../api/reportMutations";
import { recordLevelReportById } from "../../../api/reportQueries";
import { useParams, useHistory } from "react-router-dom";
import SpinningLoader from "../../Loaders/SpinningLoader";
import styled from "@emotion/styled/macro";
import Spinner from "../../Loaders/Spinner";
import { dynamicViews } from "../../../common/paths";
import ErrorMessages from "../../Notifications/ErrorMessages";
import { ErrorMessage } from "@hookform/error-message";
import PagedTable from "../../Table/PagedTable";
import ActionWrapper from "../../ActionsWrapper";
import Card from "../../Card";
import StyledLink from "../../StyledLink";
import DataSourceName from "../../Card/DataSourceName";

const FullHeightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

//Sources
const BulkAddSources = ({
  currentTags,
  setShowBulkAdd,
  updateSources,
  sources,
}) => {
  const [selected, setSelected] = useState([]);

  const [{ loading, errors, data }, fetchSources] = useApi();

  const dataSources = data?.availableDataSources?.edges ?? [];
  const totalCount = data?.availableDataSources?.totalCount;
  const pageInfo = data?.availableDataSources?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      if (currentTags) {
        fetchSources({
          query: availableDataSources,
          variables: {
            first: pageSize,
            after: cursor,
            tagFilter: {
              tagIds: [
                ...currentTags?.map((tf) => {
                  return tf?.tagId;
                }),
              ],
              exactMatch: false,
            },
            where: {
              enabled: { eq: true },
            },
            order: {
              name: "ASC",
            },
          },
        });
      } else {
        fetchSources({
          query: availableDataSources,
          variables: {
            first: pageSize,
            after: cursor,
            tagFilter: { tagIds: [], exactMatch: false },
            where: {
              enabled: { eq: true },
            },
            order: {
              name: "ASC",
            },
          },
        });
      }
    },
    [currentTags, fetchSources]
  );

  useEffect(() => {}, [currentTags, fetchSources]);

  useEffect(() => {
    if (data && currentTags?.length) {
      const sourcesToAdd = data?.availableDataSources?.edges?.map((source) => {
        return { ...source?.node, sourceId: source?.node?.id, columnId: null };
      });

      const filteredSources = sourcesToAdd.filter(
        (newSource) => !sources.some((source) => source.id === newSource.id)
      );

      setSelected(filteredSources);
    }
  }, [data, setSelected, currentTags, sources]);

  const updateLocally = useCallback(
    (sourceId, source) => {
      const foundInternally = selected.find((ds) => ds.id === sourceId);
      if (foundInternally) {
        setSelected((prevState) => {
          return prevState.filter((s) => s.id !== sourceId);
        });
      } else {
        setSelected((prevState) => [
          { ...source, sourceId: sourceId, columnId: null },
          ...prevState,
        ]);
      }
    },
    [setSelected, selected]
  );

  const updatePKLocally = useCallback(
    (sourceId, columnId) => {
      const foundInternally = selected.find((ds) => ds.id === sourceId);
      if (foundInternally) {
        setSelected((prevState) => {
          const cleanedArray = prevState.filter((s) => s.id !== sourceId);

          return [
            ...cleanedArray,
            { ...foundInternally, sourceId: sourceId, columnId },
          ];
        });
      }
    },
    [setSelected, selected]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      width: 220,
      Cell: ({ row: { original } }) => {
        const foundInternally = selected.find(
          (ds) => ds.sourceId === original?.node?.id
        );
        const isSelected = foundInternally;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              name={`columns.${original?.node?.id}`}
              label="Select Policy"
              checked={isSelected}
              disabled={sources.find(
                (source) => source.sourceId === original?.node?.id
              )}
              onChange={() => updateLocally(original?.node?.id, original?.node)}
            />
            <div style={{ marginLeft: "1rem" }}>
              <DataSourceName dataSource={original?.node} />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Grouping Key Alias",
      id: "gka",
      Cell: ({ row: { original } }) => {
        const columnOptions =
          original?.node?.columns?.map((c) => {
            return {
              value: c?.id,
              label: c?.name,
            };
          }) ?? [];
        const foundInternally = selected.find(
          (ds) => ds?.sourceId === original?.node?.id
        );
        const isSelected = foundInternally;
        return (
          <div>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`Column`}
              id={`Column`}
              inputId={`Column-input`}
              instanceId={`Column-instance`}
              label="Column"
              options={columnOptions}
              placeholder={`Select Key Column`}
              value={columnOptions.find(
                (nc) =>
                  nc?.value ===
                  selected.find((s) => s.sourceId === original?.node?.id)
                    ?.columnId
              )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) => updatePKLocally(original?.node?.id, e?.value)}
              isDisabled={!isSelected}
            />
          </div>
        );
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={
          currentTags
            ? `Confirm Bulk Add Sources ${selected.length}`
            : `Select Sources`
        }
        hide={() => setShowBulkAdd(false)}
      >
        {errors ? <ErrorMessages errors={errors} /> : null}
        <p>
          Select the sources you wish to include in the report as well is the
          Grouping Key Alias you wish to use
        </p>

        <PagedTable
          fetchData={fetchData}
          loading={loading}
          pageInfo={pageInfo}
          totalCount={totalCount}
          data={dataSources}
          columns={columnsData}
          defaultPageSize={50}
        />

        <FormActions>
          <Button
            type="button"
            list
            danger
            onClick={() => {
              setShowBulkAdd(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={!selected?.length}
            onClick={() => {
              updateSources(selected);
            }}
          >
            Add {selected?.length ? `(${selected?.length})` : null}
          </Button>
        </FormActions>
      </Modal>
    </>
  );
};

//Policies
const BulkAddPolicies = ({
  currentTags,
  setShowBulkAdd,
  updatePolicies,
  rules,
}) => {
  const [selected, setSelected] = useState([]);

  const [{ loading, errors, data }, fetchPoliciesWithTag] = useApi();

  const standards = data?.availableBusinessRuleStandards?.edges ?? [];

  //Fetch for Table Paged
  useEffect(() => {
    if (currentTags) {
      fetchPoliciesWithTag({
        query: simpleStandardsList,
        variables: {
          first: 9999,
          tagFilter: {
            tagIds: [
              ...currentTags?.map((tf) => {
                return tf?.tagId;
              }),
            ],
            exactMatch: false,
          },
          standardType: "RULE",
          where: {
            enabled: { eq: true },
          },
          order: {
            name: "ASC",
          },
        },
      });
    } else {
      fetchPoliciesWithTag({
        query: simpleStandardsList,
        variables: {
          first: 9999,
          tagFilter: { tagIds: [], exactMatch: false },
          standardType: "RULE",
          where: {
            enabled: { eq: true },
          },
          order: {
            name: "ASC",
          },
        },
      });
    }
  }, [fetchPoliciesWithTag, currentTags]);

  useEffect(() => {}, [currentTags, fetchPoliciesWithTag]);

  useEffect(() => {
    if (data && currentTags?.length) {
      const rulesToAdd = data?.availableBusinessRuleStandards?.edges?.map(
        (standard) => {
          return { ...standard?.node };
        }
      );

      const filteredPolicies = rulesToAdd.filter(
        (newPolicy) => !rules.some((rule) => rule.id === newPolicy.id)
      );

      setSelected(filteredPolicies);
    }
  }, [data, setSelected, currentTags, rules]);

  const updateLocally = useCallback(
    (node) => {
      const foundInternally = selected.find((ds) => ds?.id === node?.id);
      if (foundInternally) {
        setSelected((prevState) => {
          return prevState.filter((s) => s?.id !== node?.id);
        });
      } else {
        setSelected((prevState) => [...prevState, { ...node }]);
      }
    },
    [setSelected, selected]
  );

  const selectAll = useCallback(() => {
    const rulesToAdd = data?.availableBusinessRuleStandards?.edges?.map(
      (standard) => {
        return { ...standard?.node };
      }
    );

    const filteredPolicies = rulesToAdd.filter(
      (newPolicy) => !rules.some((rule) => rule.id === newPolicy.id)
    );

    setSelected(filteredPolicies);
  }, [setSelected, selected, data, rules]);

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      width: 220,
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        const foundInternally = selected.find(
          (ds) => ds?.id === original?.node?.id
        );
        const isSelected = foundInternally;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              name={`columns.${original?.node?.id}`}
              label="Select Policy"
              checked={isSelected}
              disabled={rules.find((rule) => rule.id === original?.node?.id)}
              onChange={() => updateLocally(original?.node)}
            />
            <div style={{ marginLeft: "1rem" }}>{original?.node?.name}</div>
          </div>
        );
      },
    },
    {
      Header: "Tags",
      id: "tags",
      accessor: (d) => {
        const output = [];
        d?.node?.tagInstances?.map((ti) => {
          return output.push(ti?.tag?.name);
        });
        return output;
      },
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={
          currentTags
            ? `Confirm Bulk Add Policies ${selected.length}`
            : `Select Policies`
        }
        hide={() => setShowBulkAdd(false)}
      >
        {errors ? <ErrorMessages errors={errors} /> : null}

        <Button type="button" onClick={() => selectAll()}>
          Select All
        </Button>

        <SortTable
          data={standards ?? []}
          columns={columnsData}
          defaultPageSize={50}
          loading={loading}
        />

        <FormActions>
          <Button
            type="button"
            list
            danger
            onClick={() => {
              setShowBulkAdd(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            disabled={!selected?.length}
            onClick={() => {
              updatePolicies(selected);
            }}
          >
            Add {selected?.length ? `(${selected?.length})` : null}
          </Button>
        </FormActions>
      </Modal>
    </>
  );
};

// Function to extract unique standard IDs across all sources
function extractUniqueStandardIdsAcrossSources(sources) {
  if (sources?.length) {
    // Initialize a Set to hold unique standard IDs across all sources
    const uniqueStandardIds = new Set();

    // Iterate over each source
    sources?.forEach((source) => {
      // Iterate over rule instances of the current source
      source?.ruleInstances?.edges?.forEach((edge) => {
        if (edge?.node?.enabledState === "PUBLISHED") {
          uniqueStandardIds.add(edge.node.standardId); // Add standard ID to the Set
        }
      });
    });

    // Convert the Set to an array to get the unique standard IDs across all sources
    return Array.from(uniqueStandardIds);
  } else {
    return [];
  }
}

const PolicySelection = ({
  rules,
  sources,
  register,
  removeRules,
  setCurrentStep,
  control,
  setValue,
  viewId,
  getValues,
  errors,
  trigger,
  reportData,
  currentSourceInstances,
}) => {
  // Extract and log the unique standard IDs for each source
  const uniqueStandardIds = extractUniqueStandardIdsAcrossSources(
    currentSourceInstances?.availableDataSources?.edges?.map((s) => s.node) ??
      []
  );

  const [currentTags, setCurrentTags] = useState([]);
  const [showBulkAdd, setShowBulkAdd] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [selectTagKey, setSelectTagKey] = useState(0);

  const clearTags = () => {
    setSelectTagKey((prevKey) => prevKey + 1);
  };

  const handleTagChange = React.useCallback(
    (tags) => {
      setCurrentTags(tags);
    },
    [setCurrentTags]
  );

  const updatePolicies = React.useCallback(
    (newPolicies) => {
      // be sure to not add duplicates
      const filteredPolicies = newPolicies.filter(
        (newPolicy) => !rules.some((rule) => rule.id === newPolicy.id)
      );

      // set the new values for policies
      setValue("rules", [...rules, ...filteredPolicies]);
      // clear the tags and iterate to clear out the select tags then hide modal
      setCurrentTags([]);
      clearTags();
      setShowBulkAdd(false);
      setShowAdd(false);
    },
    [setValue, rules]
  );

  const rulesData = [
    {
      Header: "Name",
      id: "Name",
      width: 220,
      accessor: "name",
      Cell: ({ row: { original } }) => {
        const isNotFound =
          !uniqueStandardIds.includes(original?.id) &&
          currentSourceInstances?.availableDataSources?.edges?.length;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              {original?.name}{" "}
              {isNotFound ? (
                <MdOutlineWarningAmber
                  style={{ color: "orange" }}
                  title="Policy Not Mapped to Any Selected Source."
                />
              ) : null}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Tags",
      id: "tags",
      accessor: (d) => {
        const output = [];
        d?.tagInstances?.map((ti) => {
          return output.push(ti?.tag?.name);
        });
        return output;
      },
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.tagInstances?.map((ti, i) => {
              const isLast = original?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      sortable: false,
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <TableButton
              type="button"
              danger
              onClick={() => removeRules(row?.index)}
            >
              <MdDelete style={{ fontSize: "1.2rem" }} />
            </TableButton>
          </div>
        );
      },
    },
  ];
  const reportName = getValues("reportName");
  const [showConfirm, setShowConfirm] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const [{ loading: removalLoading, data: removalData }, remove] = useApi();

  const toggleEnabled = () => {
    remove({
      query: upsertRecordLevelReport,
      variables: {
        reportConfig: {
          id: viewId,
          enabled: false,
          name: reportData?.name,
          // piiPersistenceLevel: data?.piiPersistenceLevel?.value, // Temp Disabled until needed
          groupingKeyAlias: reportData?.groupingKeyAlias,
          runsAllRules: true,
          rules: rules?.map((rule) => rule?.id),
          sources: sources?.map((source) => {
            return {
              sourceId: source?.sourceId,
              columnId: source?.columnId,
            };
          }),
        },
      },
    });
  };

  let history = useHistory();

  //handle remove report
  useEffect(() => {
    if (removalData) {
      history.push(dynamicViews());
      setShowConfirm(false);
      setReportToDelete(null);
    }
  }, [removalData, history]);

  useEffect(() => {
    if (reportToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [reportToDelete]);

  return (
    <div>
      {showConfirm ? (
        <Modal title={`Remove Report`} hide={() => setReportToDelete(null)}>
          <p>Are you sure you wish to remove this report?</p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleEnabled(reportToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setReportToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {showBulkAdd ? (
        <BulkAddPolicies
          showBulkAdd={showBulkAdd}
          setShowBulkAdd={setShowBulkAdd}
          currentTags={currentTags}
          updatePolicies={updatePolicies}
          rules={rules}
        />
      ) : null}

      {showAdd ? (
        <BulkAddPolicies
          showBulkAdd={showAdd}
          setShowBulkAdd={setShowAdd}
          updatePolicies={updatePolicies}
          rules={rules}
        />
      ) : null}

      <h3>{viewId ? `Edit - ${reportName}` : "Create View"}</h3>

      <FormControl>
        <Label>Report Name</Label>
        <StyledInput
          type="text"
          placeholder="Report Name"
          {...register("reportName", { required: "Report Name is required." })}
        />

        <ErrorMessage
          errors={errors}
          name="reportName"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
      </FormControl>

      <FormControl>
        <Label>Primary Key Common Name</Label>
        <StyledInput
          type="text"
          placeholder="Grouping Key Alias Name"
          {...register("groupingKeyAlias", {
            required: "Grouping Key Alias Name is required.",
          })}
        />

        <ErrorMessage
          errors={errors}
          name="groupingKeyAlias"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
      </FormControl>

      {/* <FormControl>
        <Label>PII Persistence Level</Label>
        <Controller
          name="piiPersistenceLevel"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              id={`alertType`}
              inputId={`alertType-input`}
              instanceId={`alertType-instance`}
              label="Alert Type"
              options={alertTypes}
              isOptionDisabled={(option) => option.disabled}
              placeholder={`Alert Type`}
              menuPortalTarget={document.body}
              menuPlacement="auto"
            />
          )}
        />
      </FormControl> */}

      {/* <FormControl>
        <label style={{ cursor: "pointer", marginRight: "1rem" }}>
          <input
            type="checkbox"
            placeholder="Run All Rules"
            {...register("runsAllRules")}
          />
          Runs All Rules
        </label>
      </FormControl> */}

      <h3>Selected Policies {rules?.length ? `(${rules?.length})` : null}</h3>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Button
            type="button"
            list
            onClick={() => setShowAdd((prev) => !prev)}
          >
            Add Policies
          </Button>
          <Button type="button" danger onClick={() => setValue("rules", [])}>
            Clear All
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              marginRight: "1rem",
              position: "relative",
              top: "-1.1rem",
            }}
          >
            <SelectTags
              key={selectTagKey}
              loadingIndicator={false}
              updateTags={handleTagChange}
            />
          </div>
          <div>
            <Button
              type="button"
              disabled={!currentTags?.length}
              onClick={() => setShowBulkAdd(true)}
            >
              Bulk add policies
            </Button>
          </div>
        </div>
      </div>

      <SortTable data={rules ?? []} columns={rulesData} defaultPageSize={50} />

      <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        <div>
          <Button
            danger={true}
            list="true"
            type="button"
            title="Archive Toggle"
            id={`removeDynamicRemove`}
            data-testid={`removeDynamicRemove`}
            onClick={() =>
              setReportToDelete({
                id: viewId,
                enabled: false,
              })
            }
          >
            Remove
          </Button>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            onClick={async () => {
              const result = await trigger(["reportName"]);

              if (result) {
                setCurrentStep(1);
              }
            }}
            disabled={Object.keys(errors)?.length !== 0}
          >
            Next: Select Sources
          </Button>
        </div>
      </div>
    </div>
  );
};

function findUnmappedPolicies(rules, currentSourceInstances) {
  // Extract and log the unique standard IDs for each source
  const uniqueStandardIds = extractUniqueStandardIdsAcrossSources(
    currentSourceInstances?.availableDataSources?.edges?.map((s) => s.node) ??
      []
  );

  //flat policy Ids from

  let unmappedRules = [];
  if (uniqueStandardIds?.length && rules?.length) {
    //for each rule, see if its on a source, if not push to unmapped

    rules.forEach((r) => {
      const isNotFound =
        !uniqueStandardIds.includes(r?.id) &&
        currentSourceInstances?.availableDataSources?.edges?.length;
      if (isNotFound) {
        unmappedRules.push({ id: r?.id, name: r?.name });
      }
    });

    return unmappedRules;
  } else {
    return [];
  }
}

const SourceSelection = ({
  sources,
  removeSources,
  setCurrentStep,
  getValues,
  setValue,
  upsertLoading,
  upsertErrors,
  rules,
  currentSourceInstances,
}) => {
  const reportName = getValues("reportName");
  const [currentTags, setCurrentTags] = useState([]);
  const [showBulkAdd, setShowBulkAdd] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [selectTagKey, setSelectTagKey] = useState(0);

  const clearTags = () => {
    setSelectTagKey((prevKey) => prevKey + 1);
  };

  const handleTagChange = React.useCallback(
    (tags) => {
      setCurrentTags(tags);
    },
    [setCurrentTags]
  );

  const updateSources = React.useCallback(
    (newSources) => {
      // be sure to not add duplicates
      const filteredSources = newSources.filter(
        (newSource) => !sources.some((source) => source.id === newSource.id)
      );

      // set the new values for sources
      setValue("sources", [...sources, ...filteredSources]);
      // clear the tags and iterate to clear out the select tags then hide modal
      setCurrentTags([]);
      clearTags();
      setShowBulkAdd(false);
      setShowAdd(false);
    },
    [setValue, sources]
  );

  const updateSourcePK = React.useCallback(
    (sourceId, columnId) => {
      const updatedSources = sources.map((source) =>
        source.id === sourceId ? { ...source, columnId } : source
      );

      // set the new values for sources
      setValue("sources", updatedSources);

      // clear the tags and iterate to clear out the select tags then hide modal
      setCurrentTags([]);
      clearTags();
      setShowBulkAdd(false);
      setShowAdd(false);
    },
    [setValue, sources]
  );

  const identifyPoliciesNotMapped = findUnmappedPolicies(
    rules,
    currentSourceInstances
  );

  const rulesData = [
    {
      Header: "Name",
      id: "Name",
      width: 220,
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <DataSourceName dataSource={original} />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Grouping Key Alias",
      id: "gka",
      Cell: ({ row: { original } }) => {
        const columnOptions =
          original?.columns?.map((c) => {
            return {
              value: c?.id,
              label: c?.name,
            };
          }) ?? [];

        return (
          <div>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`Column`}
              id={`Column`}
              inputId={`Column-input`}
              instanceId={`Column-instance`}
              label="Column"
              options={columnOptions}
              placeholder={`Select Key Column`}
              value={columnOptions.find(
                (nc) =>
                  nc?.value ===
                  sources.find((s) => s.sourceId === original?.id)?.columnId
              )}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) => updateSourcePK(original?.id, e?.value)}
            />
          </div>
        );
      },
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.tagInstances?.map((ti, i) => {
              const isLast = original?.tagInstances?.length - 1 <= i;
              return (
                <div
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      sortable: false,
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <TableButton
              type="button"
              danger
              onClick={() => removeSources(row?.index)}
            >
              <MdDelete style={{ fontSize: "1.2rem" }} />
            </TableButton>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {showBulkAdd ? (
        <BulkAddSources
          showBulkAdd={showBulkAdd}
          setShowBulkAdd={setShowBulkAdd}
          currentTags={currentTags}
          updateSources={updateSources}
          sources={sources}
        />
      ) : null}

      {showAdd ? (
        <BulkAddSources
          showBulkAdd={showAdd}
          setShowBulkAdd={setShowAdd}
          updateSources={updateSources}
          sources={sources}
        />
      ) : null}

      <h4 style={{ marginBottom: "1rem" }}>Dynamic View - {reportName}</h4>

      {identifyPoliciesNotMapped?.length ? (
        <p style={{ cursor: "pointer" }} onClick={() => setCurrentStep(0)}>
          <MdOutlineWarningAmber
            style={{ color: "orange", marginRight: ".5rem" }}
            title="Policies Not Mapped to Any Selected Source."
          />
          Policies Have been found that are not mapped to any selected source.
        </p>
      ) : null}

      <h3>
        Selected Sources {sources?.length ? `(${sources?.length})` : null}
      </h3>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Button
            type="button"
            list
            onClick={() => setShowAdd((prev) => !prev)}
          >
            Add Sources
          </Button>
          <Button type="button" danger onClick={() => setValue("sources", [])}>
            Clear All
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              marginRight: "1rem",
              position: "relative",
              top: "-1.1rem",
            }}
          >
            <SelectTags
              key={selectTagKey}
              loadingIndicator={false}
              updateTags={handleTagChange}
            />
          </div>
          <div>
            <Button
              type="button"
              disabled={!currentTags?.length}
              onClick={() => setShowBulkAdd(true)}
            >
              Bulk add Sources
            </Button>
          </div>
        </div>
      </div>

      <SortTable
        data={sources ?? []}
        columns={rulesData}
        defaultPageSize={50}
      />

      {upsertErrors ? <ErrorMessages errors={upsertErrors} /> : null}

      <FormActions>
        <Button list type="button" onClick={() => setCurrentStep(0)}>
          Back
        </Button>

        <Button type="submit" disabled={upsertLoading}>
          {upsertLoading ? <Spinner /> : "Submit"}
        </Button>
      </FormActions>
    </div>
  );
};

// placeholder if they want some success screen instead
// const SuccessMessage = () => {
//   return (
//     <div>
//       <h3>Success</h3>
//     </div>
//   );
// };

// Load existing handler
function DynamicViewFormInit() {
  //get view id from url
  let params = useParams();
  const viewId = params?.viewId ? Number(params?.viewId) : null;

  const [{ loading, errors, data }, fetchReport] = useApi();
  const reportData = data?.recordLevelReportById;
  useEffect(() => {
    if (viewId) {
      fetchReport({
        query: recordLevelReportById,
        variables: {
          id: viewId,
        },
      });
    }
  }, [viewId, fetchReport]);

  if (errors?.length) {
    return <ErrorMessages errors={errors} />;
  }

  if (viewId && loading)
    return (
      <FullHeightContainer>
        <SpinningLoader text="Loading View Configuration" />
      </FullHeightContainer>
    );

  return <DynamicViewCreate viewId={viewId} reportData={reportData} />;
}

// render the form with id and data if exists
function DynamicViewCreate({ viewId, reportData }) {
  let history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      rules: reportData?.rules?.map((rule) => rule.rule) ?? [],
      sources:
        reportData?.sources?.map((source) => {
          return {
            sourceId: source?.sourceId,
            columnId: source?.columnId,
            ...source.source,
          };
        }) ?? [],
      reportName: reportData?.name ?? "",
      // piiPersistenceLevel: alertTypes.find(
      //   (at) => at.value === reportData?.piiPersistenceLevel
      // ) ?? { value: "ALL", label: "All" },
      runsAllRules: true,
      groupingKeyAlias: reportData?.groupingKeyAlias ?? "",
    },
  });

  const { fields: rules, remove: removeRules } = useFieldArray({
    control,
    name: "rules",
    keyName: "key",
  });
  const { fields: sources, remove: removeSources } = useFieldArray({
    control,
    name: "sources",
    keyName: "key",
  });

  const [
    {
      loading: currentSourceInstancesLoading,
      errors: currentSourceInstancesErrors,
      data: currentSourceInstances,
    },
    fetchCurrentSourceInstances,
  ] = useApi();

  useEffect(() => {
    if (sources) {
      fetchCurrentSourceInstances({
        query: availableDataSourcesInstances,
        variables: {
          first: sources?.length,
          tagFilter: { tagIds: [], exactMatch: false },
          where: {
            enabled: { eq: true },
            id: { in: sources.map((s) => s.id) },
          },
          order: {
            name: "ASC",
          },
          firstInstances: 9999,
        },
      });
    }
  }, [sources]);

  const [
    { loading: upsertLoading, errors: upsertErrors, data: upsertData },
    upsertRecord,
  ] = useApi();

  useEffect(() => {
    if (upsertData && !upsertErrors?.length) {
      history.push(dynamicViews());
    }
  }, [upsertData, upsertErrors, history]);

  const onSubmit = (data) => {
    upsertRecord({
      query: upsertRecordLevelReport,
      variables: {
        reportConfig: {
          ...(viewId && {
            id: viewId,
          }),
          enabled: true,
          name: data?.reportName,
          // piiPersistenceLevel: data?.piiPersistenceLevel?.value, // Temp Disabled until needed
          groupingKeyAlias: data?.groupingKeyAlias,
          runsAllRules: true,
          rules: data?.rules?.map((rule) => rule?.id),
          sources: data?.sources?.map((source) => {
            return {
              sourceId: source?.sourceId,
              columnId: source?.columnId,
            };
          }),
        },
      },
    });
  };

  function handleReportView(step) {
    switch (step) {
      case 1: {
        return (
          <>
            <SourceSelection
              viewId={viewId}
              register={register}
              sources={sources}
              rules={rules}
              removeSources={removeSources}
              setCurrentStep={setCurrentStep}
              getValues={getValues}
              setValue={setValue}
              upsertLoading={upsertLoading}
              upsertErrors={upsertErrors}
              currentSourceInstances={currentSourceInstances}
            />
            {currentSourceInstancesErrors && (
              <ErrorMessages errors={currentSourceInstancesErrors} />
            )}
          </>
        );
      }
      default: {
        return (
          <>
            <PolicySelection
              viewId={viewId}
              reportData={reportData}
              sources={sources}
              register={register}
              rules={rules}
              removeRules={removeRules}
              setCurrentStep={setCurrentStep}
              control={control}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              trigger={trigger}
              currentSourceInstances={currentSourceInstances}
            />
            {currentSourceInstancesErrors && (
              <ErrorMessages errors={currentSourceInstancesErrors} />
            )}
          </>
        );
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginTop: "1rem" }}>{handleReportView(currentStep)}</div>
    </form>
  );
}

const Body = () => {
  return <DynamicViewFormInit />;
};

//Actions Section of Widget
function Actions() {
  return (
    <>
      <ActionWrapper>
        <StyledLink
          data-testid="backTo-dynamicview"
          to={dynamicViews}
          title="Back"
        >
          <MdArrowBack />
        </StyledLink>
      </ActionWrapper>
    </>
  );
}

const CardWrapper = () => {
  return (
    <Card
      title={"DYNAMIC VIEWS"}
      titleDescription={"CREATE REPORTS TO AGGREGATE EXCEPTIONS ACROSS SOURCES"}
      body={Body}
      actions={() => Actions()}
    />
  );
};

export default CardWrapper;
