import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import Button from "../../../components/Button";
import NewReportForm from "./NewReportForm";
import { Route, Switch, useHistory } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import PagedTable from "../../../components/Table/PagedTable";
import TableButton from "../../../components/Button/TableButton";
import StyledLink from "../../../components/StyledLink";
import { allCustomReports } from "../../../api/reportQueries";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import Spinner from "../../../components/Loaders/Spinner";
import { MdArchive } from "react-icons/md";
import {
  setCustomReportEnabledFlag,
  initiateCustomReport,
  createCsvReportFailureFile,
} from "../../../api/dataSourceMutations";
import Modal from "../../../components/Modal";
import Notification from "../../../components/Notification";
import { dataSourceManageReportingNew } from "../../../common/paths";

const TableHeader = styled.div`
  display: flex;
  align-items: center;
`;
const TableHeaderActions = styled.div`
  flex: 1;
  display: flex;
`;

const TableHeaderActionItems = styled.div`
  margin-left: auto;
`;

const SingleRowFlex = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayName = styled.div`
  flex: 1;
`;

const TableLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const ReportListTable = ({ sourceId, reportAvailable }) => {
  const [{ loading, errors, data }, doFetch] = useApi();
  const [showExport, setShowExport] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const [{ loading: removalLoading, data: removalData }, remove] = useApi();

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setCustomReportEnabledFlag,
      variables: { reportId: id, enabled: !enabled },
    });
  };

  //static reports
  const staticReports = [
    {
      node: {
        static: true,
        reportType: "CLONE_DATA_SOURCE",
        name: "Current Data CSV",
      },
    },
    {
      node: {
        static: true,
        reportType: "ALL_SUCCESSES",
        name: "All Successes",
      },
    },
    {
      node: {
        static: true,
        reportType: "ALL_FAILURES",
        name: "All Failures",
      },
    },
  ];

  const availReports = data?.allCustomReports?.edges ?? [];
  const updatedAvailReports = staticReports.concat(availReports);
  const totalCount = data?.allCustomReports?.totalCount;
  const pageInfo = data?.allCustomReports?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: allCustomReports,
        variables: {
          first: pageSize,
          after: cursor,
          where: {
            dataSourceId: { eq: Number(sourceId) },
            enabled: { eq: true },
          },
        },
      });
    },
    [doFetch, sourceId]
  );

  const [{ loading: csvLoading, errors: csvErrors, data: csvData }, createCSV] =
    useApi();

  useEffect(() => {
    if (csvData) {
      setShowExport(true);
    }
  }, [csvData, setShowExport]);

  const createCSVReport = useCallback(
    ({ refreshSummaryId, reportId }) => {
      createCSV({
        query: initiateCustomReport,
        variables: {
          refreshSummaryId: refreshSummaryId,
          reportId: reportId,
        },
      });
    },
    [createCSV]
  );

  const createStaticCSVReport = useCallback(
    ({ failureType, dataSourceRefreshSummaryId, ruleInstanceVersionId }) => {
      createCSV({
        query: createCsvReportFailureFile,
        variables: {
          dataSourceRefreshSummaryId: dataSourceRefreshSummaryId,
          failureType: failureType,
          ruleInstanceVersionId: ruleInstanceVersionId,
        },
      });
    },
    [createCSV]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      Cell: ({ row: { original } }) => {
        return (
          <SingleRowFlex>
            <DisplayName>{original?.node?.name}</DisplayName>{" "}
          </SingleRowFlex>
        );
      },
    },
    {
      Header: "Export Enabled",
      id: "enabled",
      Cell: ({ row: { original } }) => {
        return <div>{original?.node?.exportReport ? "True" : "False"}</div>;
      },
    },
    {
      Header: " ",
      id: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.static ? null : (
              <>
                <StyledLink
                  style={{ marginRight: "1rem" }}
                  to={`/sources/${sourceId}/manage/reporting/edit/${original?.node?.id}`}
                  title="Edit Workflow"
                >
                  Edit
                </StyledLink>
                <TableButton
                  list="true"
                  type="button"
                  danger={true}
                  bumpdown={true}
                  onClick={() =>
                    setReportToDelete({
                      id: original?.node?.id,
                      enabled: original?.node?.enabled,
                    })
                  }
                >
                  <MdArchive />
                </TableButton>
              </>
            )}
            {original?.node?.static ? null : (
              <TableButton
                type="button"
                title="Export"
                disabled={csvLoading}
                onClick={() =>
                  createCSVReport({
                    refreshSummaryId: reportAvailable?.refreshSummaryId,
                    reportId: original?.node?.id,
                  })
                }
              >
                {csvLoading ? <Spinner /> : "Export"}
              </TableButton>
            )}
            {original?.node?.static && reportAvailable ? (
              <TableButton
                type="button"
                title="Export"
                disabled={csvLoading}
                onClick={() =>
                  createStaticCSVReport({
                    failureType: original?.node?.reportType,
                    dataSourceRefreshSummaryId:
                      reportAvailable?.refreshSummaryId,
                    ruleInstanceVersionId: null,
                  })
                }
              >
                {csvLoading ? <Spinner /> : "Export"}
              </TableButton>
            ) : null}
          </div>
        );
      },
    },
  ];

  //handle remove rule update
  useEffect(() => {
    if (removalData) {
      doFetch({
        query: allCustomReports,
        variables: {
          first: 10,
          where: {
            dataSourceId: { eq: Number(sourceId) },
            enabled: { eq: true },
          },
        },
      });
      setShowConfirm(false);
      setReportToDelete(null);
    }
  }, [removalData, doFetch, sourceId]);

  useEffect(() => {
    if (reportToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [reportToDelete]);

  const resetExport = useCallback(() => {
    setShowExport(false);
  }, [setShowExport]);

  return (
    <>
      {showExport ? (
        <TableLoading>
          <Notification
            closeCallBack={resetExport}
            text="Your data extract is being prepared; an email will be sent to you when the extract is ready to download."
          />
        </TableLoading>
      ) : null}

      {showConfirm ? (
        <Modal
          title={`Confirm Report ${
            reportToDelete?.enabled ? "Removal" : "Reactivation"
          }`}
          hide={() => setReportToDelete(null)}
        >
          <p>
            Are you sure you wish to{" "}
            {reportToDelete?.enabled ? "remove" : "reactivate"} this report?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading}
              danger
              onClick={() => toggleSourceEnabled(reportToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setReportToDelete(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      ) : null}

      {errors ? <ErrorMessages errors={errors} /> : null}
      {csvErrors ? <ErrorMessages errors={csvErrors} /> : null}

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={updatedAvailReports}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

const ReportingList = ({ sourceId, reportAvailable }) => {
  let history = useHistory();
  return (
    <div>
      <TableHeader>
        <h3>Reports</h3>
        <TableHeaderActions>
          <TableHeaderActionItems>
            <Button
              type="button"
              title="Add Custom Report"
              onClick={() =>
                history.push(dataSourceManageReportingNew(sourceId))
              }
            >
              Add Custom Report
            </Button>
          </TableHeaderActionItems>
        </TableHeaderActions>
      </TableHeader>

      <ReportListTable sourceId={sourceId} reportAvailable={reportAvailable} />
    </div>
  );
};

const Reporting = ({ sourceId, reportAvailable }) => {
  return (
    <div>
      <Switch>
        <Route
          path={`/sources/:sourceId/manage/reporting/new`}
          component={() => <NewReportForm sourceId={sourceId} />}
        />

        <Route
          path={`/sources/:sourceId/manage/reporting/edit/:customReportId`}
          component={() => <NewReportForm sourceId={sourceId} edit={true} />}
        />

        <Route
          path={`/sources/:sourceId/manage/reporting`}
          component={() => (
            <ReportingList
              sourceId={sourceId}
              reportAvailable={reportAvailable}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default Reporting;
