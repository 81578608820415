import React, { useEffect, useState } from "react";
import FragmentWrapper from "./FragmentWrapper";
import ConditionOperation from "./ConditionOperation";
import styled from "styled-components/macro";
import { MdWarning } from "react-icons/md";
import { dataSourceForCrosstable } from "../../api/dataSourceQueries";
import { useApi } from "../../api/useApi";
import SplashLoader from "../Loaders/SplashLoader";
import { FaDatabase } from "react-icons/fa";
import _ from "lodash";
import SortTable from "../Table/SortTable";

const RealColumnName = styled.div`
  padding: 8px;
  font-size: 0.8em;
  background: ${(props) => props.theme.surfaceAlt};
  margin-bottom: 5px;
  display: inline-block;
`;

const FragmentSourceInfo = ({ name }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: ".5rem",
      }}
    >
      <div style={{ marginRight: ".5rem" }}>
        {name ? (
          <FaDatabase />
        ) : (
          <WarningIcon>
            <MdWarning />
          </WarningIcon>
        )}
      </div>{" "}
      {name ? name : "Remote Source Not Found"}
    </div>
  );
};

const DuplicateForm = ({ columns, calculationMappings, isCrossTable }) => {
  function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.columns.push(x);
      } else {
        rv.push({ key: v, columns: [x] });
      }
      return rv;
    }, []);
  }

  const localGroups = groupByArray(calculationMappings, "argument") ?? [];

  const columnsData = [
    {
      Header: "Unique Collections",
      id: "CollectionId",
      Cell: ({ row }) => {
        return (
          <div>
            <div>Collection {row?.index + 1}</div>
          </div>
        );
      },
    },
    ...(localGroups?.[0]?.columns?.map((lgc, i) => {
      return {
        Header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "1rem" }}>Column {i + 1}</div>
          </div>
        ),
        id: `column${i}`,
        Cell: ({ row }) => {
          const c = row?.original?.columns[i];

          let sourceColumn = columns?.find((col) => col.id === c?.columnId);

          const sourceColumnName =
            sourceColumn?.fullyQualifiedName?.split(/\|/);

          return (
            <div>
              {isCrossTable && (
                <FragmentSourceInfo name={sourceColumnName?.[0]} />
              )}
              <RealColumnName>
                {_.truncate(sourceColumn?.name, {
                  length: 45, // maximum 30 characters
                  separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
                })}
              </RealColumnName>
            </div>
          );
        },
      };
    }) ?? []),
  ];

  return (
    <div
      style={{
        flexGrow: 1,
        minHeight: "500px",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <div
        style={{
          overflow: "auto",
          display: "flex",
          marginTop: "1em",
          paddingBottom: "1em",
          flexWrap: "wrap",
          flexGrow: 1,
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", left: 0, right: 0 }}>
          <SortTable data={localGroups} columns={columnsData} removeOverflow />
        </div>
      </div>
    </div>
  );
};

// confirmation model
function buildTemplate(template) {
  let newCards = [];
  const sorted = template.fragments.sort(function (a, b) {
    return a.executionOrder - b.executionOrder;
  });

  sorted.forEach((frag, i) => {
    const fragType = frag.typeInformation[0].typeValue;
    if (fragType === 1) {
      // Condition
      const conditionNames = ["If", "Else If", "Else", "End If", "Then"];
      const fragment = {
        ...frag,
        name: conditionNames[frag.typeInformation[1].typeValue - 1],
        def: "conditionOperators",
      };

      newCards.push(fragment);
    } else if (fragType === 2) {
      // Condition Operator
      const conditionOperatorNames = ["And", "Or", "(", ")"];
      const fragment = {
        ...frag,
        name: conditionOperatorNames[frag.typeInformation[1].typeValue - 1],
        def: "conditionOperators",
      };
      newCards.push(fragment);
    } else if (fragType === 4) {
      // Source

      //Map Source Dropdown to value for react-select
      const source = frag;
      const operation = sorted[i + 1];

      const isByAmountOrisByPercentage =
        operation.typeInformation[2]?.typeValue === 4 ||
        operation.typeInformation[2]?.typeValue === 5 ||
        operation.typeInformation[2]?.typeValue === 7 ||
        operation.typeInformation[2]?.typeValue === 8;

      const target =
        (operation.typeInformation[1].typeValue === 6 &&
          !isByAmountOrisByPercentage) ||
        (operation.typeInformation[1].typeValue === 14 &&
          !isByAmountOrisByPercentage) ||
        (operation.typeInformation[1].typeValue === 15 &&
          !isByAmountOrisByPercentage)
          ? null
          : sorted[i + 2];

      const fragment = {
        name: "Fragment",
        def: "fragment",
        source: source,
        operation: operation,
        target: target,
      };
      newCards.push(fragment);
    }
  });
  return newCards;
}

const ViewHeaderDetails = styled.div`
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const OutOfDate = styled.div`
  background: #eee;
  padding: 0.2rem;
  display: flex;
  margin-right: 1rem;
  align-items: center;
`;

const WarningIcon = styled.div`
  color: orange;
`;

const ViewMappedRule = ({
  standard,
  mappings,
  calcMappings,
  instance,
  isCrossTable,
  sourceId,
}) => {
  const [selectedDataSources, setSelectedDataSources] = useState(null);

  //Columns
  //Get Rule
  const [{ loading: loadingSource, data: dataSourceData }] = useApi(
    dataSourceForCrosstable,
    {
      id: Number(sourceId),
      where: {
        secondaryDataSources: {
          enabled: true,
        },
      },
    }
  );

  useEffect(() => {
    if (dataSourceData) {
      setSelectedDataSources([
        { ...dataSourceData?.dataSourceForCrosstable?.primaryDataSource },
        ...dataSourceData?.dataSourceForCrosstable?.secondaryDataSources,
      ]);
    }
  }, [dataSourceData, setSelectedDataSources]);

  const columns =
    selectedDataSources
      ?.map((ds) => {
        return ds?.columns;
      })
      .flat() ?? [];

  const template = buildTemplate(standard);

  const breakPoints = template
    .filter((item) => item.name === "Then")
    .map((item) => item.executionOrder);

  const isLatest =
    instance?.standardVersion?.id ===
    instance?.standardVersion?.standard?.latestVersion?.id;

  if (loadingSource) return <SplashLoader text={"Loading Column Data"} />;

  if (standard?.id === -2) {
    return (
      <DuplicateForm
        columns={columns}
        calculationMappings={calcMappings}
        isCrossTable={isCrossTable}
      />
    );
  }

  if (standard?.id === -3) {
    return "Entire Row - Duplicate Records Check";
  }

  return (
    <>
      <ViewHeaderDetails>
        {!isLatest && (
          <OutOfDate>
            <WarningIcon>
              <MdWarning />
            </WarningIcon>{" "}
            Policy Out of Date
          </OutOfDate>
        )}
      </ViewHeaderDetails>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {template.map((fragment, i) => {
          let isAfterBreakPoint;
          breakPoints.forEach((breakPoint) => {
            if (fragment.executionOrder > breakPoint) {
              isAfterBreakPoint = true;
            }
          });
          if (fragment.def === "fragment") {
            return (
              <FragmentWrapper
                key={`fragmentWrapper-${i}`}
                fragment={fragment}
                isAfterBreakPoint={isAfterBreakPoint}
                columns={columns}
                mappings={mappings}
                calcMappings={calcMappings}
                isCrossTable={isCrossTable}
              />
            );
          } else {
            return (
              <ConditionOperation
                key={`conditionOperation-${i}`}
                fragment={fragment}
                i={i}
                template={template}
              />
            );
          }
        })}
      </div>
    </>
  );
};

export default ViewMappedRule;
